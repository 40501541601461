<template>
	<tr class="optiontr">
		<td class="labeltd">
			<span>{{label}}</span>
			<span v-if="validAnswer === false" class="invalid"><i class="mdi mdi-exclamation-thick"></i></span>
			<span v-if="validAnswer" class="valid"><i class="mdi mdi-check-bold"></i></span>
		</td>
		<td v-for="opt in question.options" :key="opt.optid" class="casetd" :class="{'selectedtd' : selections.includes(opt.optid)}" @click="toggleAnswer(opt.optid)">
			<input type="checkbox" v-model="selections" :value="opt.optid" />
		</td>
	</tr>
</template>

<script>
export default {
	name: "MatrixCheckRow",
	components: {},
	props: {
		question: Object,
		label: String,
	},
	data: function () {
		return {

		}
	},
	computed: {
		storedAnswersForQuestion() {
			if (this.$store.state.answers.find(item => item.qid === this.question.qid)) {
				return this.$store.state.answers.find(item => item.qid === this.question.qid);
			}
			return [];
		},
		selections: {
			get() {
				let ob = this.$store.state.answers.find(item => item.qid === this.question.qid);
				return ob.selections;
			},
			set(value) {
				this.$store.commit('updateAnswer', {qid: this.question.qid, selections: value});
			}
		},
		validAnswer() {
			if(this.question.mandatory === 'No') return null;
			return this.validateAnswer(this.question);
		},
	},
	methods: {
		toggleAnswer(optid) {
			if(this.selections.indexOf(optid) > -1) { //remove
				let ind = this.selections.indexOf(optid);
				this.selections.splice(ind, 1);
			}
			else {
				this.selections.push(optid);
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
table td {
	border: 1px solid #D4D8DB;
	text-align: center;
}
table td.labeltd {
	padding: 15px 15px 15px 10px;
}
table td.casetd {
	padding: 15px 20px;
	text-align: center;
}
.selectedtd {
	background-color: #23A6EE;
}
.valid, .invalid {
	font-size: 11px;
	margin-left: 5px;
	display: inline-block;
}
.invalid {
	color: #C00;
}
.valid {
	color: #0A0;
}
</style>