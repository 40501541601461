<template>
	<div class="error-box">
		<div v-if="error_type === 'survey-closed'">
			<h1>Survey closed</h1>
			<div>This survey is now closed.</div>
		</div>

		<div v-if="error_type === 'survey-notready'">
			<h1>Survey unavailable</h1>
			<div>Your opinions and responses are gratefully received and extremely important to us, and we apologise for any inconvenience.<br/>
				If you received an email invitation for this survey please try again later.<br/>
				If the problem continues please feel free to contact us and we&apos;ll do our best to rectify the problem.<br/>
			</div>
		</div>

		<div v-if="error_type === 'respondent-quotafull'">
			<div>We would like to thank you for taking the time to participate in our survey.<br/>
				<br/>Your opinions and responses are gratefully received and extremely important to us.<br/>
				<br/>The survey is now closed due to overwhelming responses from people like yourself.<br/>
				<br/>Once again thank you for your interest. To ensure that you receive further relevant surveys, please make sure that your
				details are always up to date.<br/><br/>Please <a :href='error_content.exit_url'>click here</a> to exit.
			</div>
		</div>

		<div v-if="error_type === 'respondent-alreadycompleted'">
			<div>Our records indicate that you have already completed this survey. <br/><br/>
				Thanks for your time, we will be in touch when the next relevant survey is ready.<br/><br/>
				If you haven&apos;t completed this survey and feel this message is an error, or it's not showing as completed in your rewards portal,
				please contact your research panel provider or where you were invited from.
			</div>
		</div>

		<div v-if="error_type === 'respondent-dmtresumenorecord'">
			<div>
				We are unable to find your saved responses in our database.<br/><br/>
				This is likely due to landing on this page accidentally.<br/><br/>
				Please make sure you launch the survey from the link we sent you after you saved last time, or if you received this email from an online panel (eg MyOpinions, Rewards Central, Nine Rewards etc) please resume by clicking on the link you received from them.<br/><br/>
				If you still have problems, please contact your research panel provider or where you were invited from.
			</div>
		</div>

		<div v-if="error_type === 'respondent-catiloginfail'">
			<h1>Login failed</h1>
			<div>To access this CATI survey you need to be logged in to Wavelength as a CATI Operator or Supervisor.<br/><br/>
				Please login to Wavelength first and launch this call from the CATI dashboard.<br/><br/>
				You may need to log out then log back in again if it's been a while since you last logged in.<br/><br/>
				If you continue to see this error after logging in please contact us.<br/><br/>
				<a href="#" @click="returnToCatiHome">Click here to return to the CATI Survey screen</a>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "ErrorBox",
	components: {},
	props: {
		error_type: String,
		error_content: Object,
	},
	data: function () {
		return {}
	},
	computed: {},
	methods: {
		returnToCatiHome() {
			this.clearLocalStorage();
			window.location = "https://app.yourwavelength.com/cati-launch/" + this.$store.state.survid;
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.error-box {
	margin-top: 50px;
	text-align: center;
}
h1 {
	font-size: 24px;
}
a {
	text-decoration: underline;
}
</style>