<template>
	<div class="question-section" v-if="activepageno === question.pageNo && !hiddenForPanel">
		<p class="qtext" v-html="niceQtext"></p>
		<p v-if="question.mandatory === 'Yes' && $store.state.matrixTypes.indexOf(question.type) === -1" style="margin-top: 5px;">
			<span v-if="!hasValidAnswer" class="reqtext">
				<span v-if="this.$store.state.surveyOb.emailq === this.question.qid">*Please enter a valid email address</span>
				<span v-else>*Required</span>
			</span>
			<span v-if="hasValidAnswer" class="valid"><i class="mdi mdi-check-bold"></i> Answer OK!</span>
		</p>

		<MediaEmbed v-if="question.filelink && question.filelink.length > 0" :filename="question.filelink"></MediaEmbed>

		<br/>

		<MatrixQueston v-if="question.type === 'MatrixHeadRadio' || question.type === 'MatrixHeadCheck'" :question="question"></MatrixQueston>
		<RadioQ v-if="question.type === 'Radio'" :question="question" v-on:nextpage="$emit('nextpage')"></RadioQ>
		<CheckBoxQ v-if="question.type === 'Checkbox'" :question="question"></CheckBoxQ>
		<SelectOne v-if="question.type === 'Select-One'" :question="question"></SelectOne>
		<TextAreaQ v-if="(question.type === 'Text' && $store.state.isCATI) || question.type === 'Textarea'" :question="question"></TextAreaQ>
		<TextQ v-if="question.type === 'Text' && !$store.state.isCATI" :question="question" type="text"></TextQ>
		<TextQ v-if="question.type === 'Number'" :question="question" type="number"></TextQ>
	</div>
</template>

<script>
import SelectOne from "@/components/question-types/SelectOne";
import TextAreaQ from "@/components/question-types/TextAreaQ";
import TextQ from "@/components/question-types/TextQ";
import MediaEmbed from "@/components/MediaEmbed";
import MatrixQueston from "@/components/question-types/MatrixQuestion";
import RadioQ from "@/components/question-types/RadioQ";
import CheckBoxQ from "@/components/question-types/CheckBoxQ";

export default {
	name: "Question",
	components: {
		CheckBoxQ,
		RadioQ,
		MatrixQueston,
		MediaEmbed,
		TextQ,
		TextAreaQ,
		SelectOne,
	},
	props: {
		question: Object,
		activepageno: Number,
	},
	data: function () {
		return {

		}
	},
	computed: {
		hasValidAnswer() {
			return this.validateAnswer(this.question);
		},
		niceQtext() {
			let myqtext = this.question.qtext;
			if(this.question.type === 'MatrixHeadRadio' || this.question.type === 'MatrixHeadCheck') {
				let arr = this.question.qtext.split("|");
				myqtext = arr[0];
			}
			return myqtext;
		},
		hiddenForPanel() {
			if(this.$store.state.getparams.source && this.$store.state.getparams.source.length > 0 && this.$store.state.getparams.source !== 'CATI') {
				let mypos = this.$store.state.surveyOb.questions.findIndex(item => item.qid === this.question.qid);
				if(mypos < this.$store.state.surveyOb.panelqignore) {
					return true;
				}
			}
			return false;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.question-section {
	padding: 15px 0;
}
.iscati .question-section {
	max-width: 1400px;
}
.qtext {
	font-size: 16px;
	font-weight: bold;
}
.reqtext {
	font-size: 11px;
	color: #C00;
}
.valid {
	color: #0A0;
	font-size: 11px;
}
</style>