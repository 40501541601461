<template>
	<div class="blanket">
		<div class="loading">
			<div class="loading-box">
				<p>Saving ... Please wait</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Loader",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.blanket {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
}
.loading {
	position: fixed;
	width: 100%;
	z-index: 1010;
}
.loading-box {
	background: url('~@/assets/preloadwh.gif') no-repeat scroll 50% 50%;
	background-color: #CCC;
	background-color: rgba(0,0,0,0.6);
	height: 200px;
	width: 20%;
	min-width: 200px;
	border-radius: 10px;
	margin: 200px auto;
	position: relative;
	padding-top: 30px;
}
.loading p {
	text-align: center;
	font-size: 14px;
	color: #FFF;
}
</style>