<template>
	<div class="survey-view">
		<div class="">
			<div id="">
				<p>Thanks for taking the survey<span v-if="fname.length > 0"> {{fname}}</span>!</p>

				<p v-if="$store.state.surveyOb.finishprizetag.length > 0">{{$store.state.surveyOb.finishprizetag}}</p>

				<p>Please <a :href="fin_exiturl">click here</a> to exit.</p>

			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Finish",
	components: {},
	props: {
		fname: String,
		exiturl: String,
	},
	data: function () {
		return {}
	},
	computed: {
		fin_exiturl() {
			if(!this.exiturl || this.exiturl.length === 0) {
				return "https://yourwavelength.com";
			}
			return this.exiturl;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.survey-view p {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 20px;
}
</style>