<template>
	<div class="survey-view">
		<div class="">
			<div id="" style="padding-top: 30px;">
				<div>
					<p>
						The remaining part of this survey will be getting your thoughts and opinions on various songs.<br/><br/>
						Each song will play about 7-10 seconds of the most recognisable part.  After it plays, you'll be asked to vote on that song.<br/><br/>
						Simply click the button that corresponds to how you feel about that song - once you've clicked the survey will move to the next song.<br/>
					</p>
				</div>

				<div v-if="isFromPanel === true">
					<p>
						Your votes will automatically save as you go.  If you wish to take a break and come back to the survey at a later time or on another day,
						you simply need to close your browser and when you're ready to resume, simply click the link on the invitation email you received to take this survey.<br/><br/>
						Make sure you don't delete that invitation email until you have completely finished the survey.  <br/><br/>
						Note that the survey may only be open for a short time so don't leave it too long!<br/><br/>
						Please allow a few seconds for the songs to load (this will depend on your internet connection speed).  If you have problems, please close your browser then click on the resume link to continue.<br/><br/>
					</p>
				</div>

				<div v-if="isFromPanel === false">
					<p>
						Your votes will automatically save as you go.  Please check the email address you entered earlier - we have sent you an email allowing you to take a break and come back to the survey at a later time or on another day.
						Simply click the link on that email we've sent you to resume the survey - just don't lose that email!<br/><br/>
						If you have not received an email from us yet, please copy and paste this link somewhere safe - this will be your resume link.<br/><br/>
						{{resumeLink}} <br/><br/>
						Note that the survey may only be open for a short time so don't leave it too long!<br/><br/>
						Please allow a few seconds for the songs to load (this will depend on your internet connection speed).  If you have problems, please close your browser then click on the resume link to continue.<br/><br/>
					</p>
				</div>

				<span class="ycbtn" @click="continueToHooks">Next</span>

			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "OMTInstructions",
	components: {},
	props: {

	},
	data: function () {
		return {}
	},
	computed: {
		isFromPanel() {
			if(this.$store.state.getparams.source && this.$store.state.getparams.source.length > 0 && this.$store.state.getparams.source !== 'CATI') {
				return true;
			}
			return false;
		},
		resumeLink() {
			return "https://survey.yourwavelength.com/"+this.$store.state.survid+"?id="+this.$store.state.respId;
		},
	},
	methods: {
		triggerResumptionEmailSend() {
			if(this.isFromPanel === false && this.$store.state.isCATI === false) {
				let formdata = {
					survid: this.$store.state.survid,
					respid: this.$store.state.respId,
					resumelink: this.resumeLink
				};
				axios.post(
					this.$store.state.urlroot + "surveys/post-collect/omt-resumption-email.php", formdata
				).then(function (response) {
					let ret = response.data; //response.data is returned info
					console.log(ret)
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		continueToHooks() {
			this.$emit('gotohooks');
		}
	},
	watch: {},
	mounted() {
		this.triggerResumptionEmailSend()
	}
}
</script>

<style scoped>
.survey-view p {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 20px;
}
.ycbtn {
	border-radius: 6px;
	cursor: pointer;
	color: #FFF;
	font-weight: bold;
	text-decoration: none;
	transition: background 0.2s linear;
	font-size: 14px;
	margin: 10px 0 20px 0;
	background-color: #23A6EE;
	width: 140px;
	display: block;
	padding: 12px 10px 10px;
	float: left;
	text-align: center;
	outline: none;
	overflow: hidden;
	box-shadow: none;
	position: relative;
}
.ycbtn:hover {
	background-color: #43C6FE !important;
}
</style>