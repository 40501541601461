<template>
	<div>
		<div class="matrix-table-wrap">
			<table>
				<thead>
					<tr class="matrix-head-row">
						<td></td>
						<td v-for="opt in question.options" :key="opt.optid">{{opt.opttext}}</td>
					</tr>
				</thead>
				<tbody>
					<MatrixRadioRow v-for="q in radioQuestions" :key="q.qid" :question="q" :label="cleanLabel(q)"></MatrixRadioRow>
					<MatrixCheckRow v-for="q in checkQuestions" :key="q.qid" :question="q" :label="cleanLabel(q)"></MatrixCheckRow>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import MatrixRadioRow from "@/components/question-types/MatrixRadioRow";
import MatrixCheckRow from "@/components/question-types/MatrixCheckRow";
export default {
	name: "MatrixQuestion",
	components: {
		MatrixCheckRow,
		MatrixRadioRow
	},
	props: {
		question: Object, //this will be the head question only
	},
	data: function () {
		return {}
	},
	computed: {
		matrixArray() {
			return this.$store.state.matrices.find(item => item.headqid === this.question.qid);
		},
		disallowedQids() { //from any matrix flows
			let temp = this.matrixArray.questions;
			let disallowedQids = [];
			for(var t=0; t<temp.length; t++) {
				let myq = temp[t];
				let activemflows = this.mflows.filter(item => item.caseQID === myq.qid);
				if (activemflows.length > 0) {
					for (let i = 0; i < activemflows.length; i++) {
						let mflow = activemflows[i];
						let parentAns = this.getAnswersAsArray(this.$store.state.surveyOb.questions.find(item => item.qid === mflow.parentID));
						if (mflow.checked === 1) {
							if (parentAns.includes(mflow.parentOpt) && !disallowedQids.includes(mflow.caseQID)) {
								disallowedQids.push(mflow.caseQID);
							}
						}
						else { //mflow active if option not checked
							if (!parentAns.includes(mflow.parentOpt) && !disallowedQids.includes(mflow.caseQID)) {
								disallowedQids.push(mflow.caseQID);
							}
						}
					}
				}
			}
			return disallowedQids;
		},
		radioQuestions() {
			let radios = ['MatrixHeadRadio','MatrixSubRadio','MatrixEndRadio'];
			let temp = this.matrixArray.questions.filter(item => radios.includes(item.type));
			return temp.filter(item => !this.disallowedQids.includes(item.qid));
		},
		checkQuestions() {
			let checks = ['MatrixHeadCheck','MatrixSubCheck','MatrixEndCheck'];
			let temp = this.matrixArray.questions.filter(item => checks.includes(item.type));
			return temp.filter(item => !this.disallowedQids.includes(item.qid));
		},
		mflows() {
			return this.$store.state.surveyOb.matrixFlows;
		},
	},
	methods: {
		cleanLabel(ques) {
			let str = '';
			if(ques.type === 'MatrixHeadRadio' || ques.type === 'MatrixHeadCheck') {
				let arr = ques.qtext.split("|");
				str = arr[1];
			}
			else str = ques.qtext;
			if(ques.mandatory === 'Yes') {
				str += "*";
			}
			return str;
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.optiontr {
	cursor: pointer;
	transition: background-color 200ms linear;
}
.optiontr:hover {
	background-color: #23A6EE;
	color: #FFF;
}
table td {
	border: 1px solid #D4D8DB;
	text-align: center;
	padding: 10px 20px;
}
table thead td {
	background-color: #EEE;
	padding-top: 10px;
	padding-bottom: 10px;
}
.matrix-table-wrap {
	overflow-x: auto;
	overflow-y: hidden;
}
.matrixinp:hover {
	background-color: #23A6EE;
}
.matrixinp label {
	min-height: 100%;
	height: auto !important;
	height: 100%;
	padding: 10px 0;
}
</style>