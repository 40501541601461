import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import Main from "@/components/views/Main";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './includes/style.css'
import './includes/skins.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.config.productionTip = false

//routing
const routes = [
	{path: '/:survid', component: Main},

]

const router = new VueRouter({
	history: true,
	hashbang: false,
	routes: routes,
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

let isDevServer = false;
let urlroot = 'https://yourwavelength.com/';
if(window.location.origin.indexOf('localhost') > -1) {
	isDevServer = true;
	urlroot = 'http://wavelength.test/';
}

const store = new Vuex.Store({
	state: {
		// sesscheckcomplete: false,
		kalert: {
			shown: false,
			text: "",
			type: 'danger',
		},
		getparams: {},
		isDevServer: isDevServer,
		urlroot: urlroot,
		respId: null,
		respToken: null, //via GET if needed for resume etc (respondent row in atbl)
		survid: 0,
		surveyOb: {
			survid: 0
		},
		wlsesstoken: '', //for logged in use, sessions table for WL users
		wlUserId: null, //will only be set for CATI operators (must be auth at server)
		answers: [],
		oanswers: [], //for OText responses where applicable
		isCATI: false,
		qTypeUsesArray: ['Checkbox', 'MatrixHeadCheck', 'MatrixSubCheck', 'MatrixEndCheck'],
		qTypeHasStringAns: ['Text','Textarea'],
		matrixTypes: ['MatrixHeadRadio','MatrixHeadCheck','MatrixSubRadio','MatrixSubCheck','MatrixEndRadio','MatrixEndCheck'],
		matrices: [],
		hookIdsRemaining: [], //this may be different to hooklist ids - has been randomised if necessary
		sessStorageExists: false, //if F5 this will be true, if DMT resume later it'll be false
	},
	getters: {
		numHooksAnswered: state => {
			if(state.surveyOb && state.surveyOb.hookcount) { //used for percentage calc
				return state.surveyOb.hookcount - state.hookIdsRemaining.length;
			}
			return 0;
		},
	},
	mutations: {
		setRespId(state, respidstr) {
			state.respId = respidstr;
		},
		setGetParams(state, obj) {
			state.getparams = obj;
		},
		setIsCati(state, boolval) {
			state.isCATI = boolval;
		},
		setWLUserId(state, userid) {
			state.wlUserId = userid;
		},
		setWLSessToken(state, token) {
			state.wlsesstoken = token;
			window.sessionStorage.setItem('wlSessToken', token);
		},
		setRespToken(state, token) {
			state.respToken = token;
		},
		setHookIds(state, arr) {
			state.hookIdsRemaining = arr;
		},
		removeHookFromRemainingList(state, hookid) {
			let myIndex = state.hookIdsRemaining.indexOf(hookid);
			if(myIndex > -1) {
				state.hookIdsRemaining.splice(myIndex, 1);
			}
		},
		updateAnswer(state, qa_ob) {
			if(qa_ob.qid > 0) {
				let myansIndex = state.answers.findIndex(item => item.qid === qa_ob.qid);
				if (myansIndex > -1) {
					Vue.set(state.answers, myansIndex, {qid: qa_ob.qid, selections: qa_ob.selections});
				}
				else {
					state.answers.push({qid: qa_ob.qid, selections: qa_ob.selections});
				}
			}
		},
		updateAnswerFromServerRetrieve(state, qa_ob) {
			if(qa_ob.qid > 0) {
				let mynewval = null;
				let myq = state.surveyOb.questions.find(item => item.qid === qa_ob.qid);
				if(state.qTypeUsesArray.indexOf(myq.type) > -1) {
					mynewval = [];
					for(let i=0; i<qa_ob.arr.length; i++) {
						mynewval.push(parseInt(qa_ob.arr[i]));
					}
				}
				else if(state.qTypeHasStringAns.indexOf(myq.type) === -1) {
					mynewval = parseInt(qa_ob.val);
				}
				else {
					mynewval = qa_ob.val;
				}
				let myansIndex = state.answers.findIndex(item => item.qid === qa_ob.qid);
				if (myansIndex > -1) {
					Vue.set(state.answers, myansIndex, {qid: qa_ob.qid, selections: mynewval});
				}
				else {
					state.answers.push({qid: qa_ob.qid, selections: mynewval});
				}
			}
		},
		initAnswerArray(state, arr) {
			state.answers = arr;
		},
		initOAnswerArray(state, oarr) {
			state.oanswers = oarr;
		},
		setOAns(state, qa_ob) {
			let myansIndex = state.oanswers.findIndex(item => item.qid === qa_ob.qid);
			if(myansIndex > -1) {
				Vue.set(state.oanswers, myansIndex, {qid: qa_ob.qid, response: qa_ob.response});
			}
			else {
				state.oanswers.push({qid: qa_ob.qid, response: qa_ob.response});
			}
		},
		setMatrices(state, arr) {
			state.matrices = arr;
		},
		showKalert(state, responseObject) {
			state.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) state.kalert.text = responseObject.text;
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger';
			if (responseObject.success === 1) responseObject.alerttype = 'success';
			state.kalert.type = responseObject.alerttype;
			if (responseObject.type !== "" && responseObject.type !== undefined) state.kalert.type = responseObject.type;
			state.kalert.shown = true;
		},
		closeKalert(state) {
			state.kalert.shown = false;
		},
		setSurvId(state, survid) {
			state.survid = survid;
		},
		setSurveyObject(state, survobj) {
			state.surveyOb = survobj;
		},
		fetchFromLocalStorage(state) {
			if(sessionStorage.getItem('answers')) {
				let ansar = JSON.parse(sessionStorage.getItem('answers'));
				for(let i=0; i<ansar.length; i++) {
					let myansIndex = state.answers.findIndex(item => item.qid === ansar[i].qid);
					if(state.answers[myansIndex]) {
						Vue.set(state.answers, myansIndex, {qid: ansar[i].qid, selections: ansar[i].selections});
					}
				}
				state.sessStorageExists = true;
			}
			if(sessionStorage.getItem('oanswers')) {
				let ansar = JSON.parse(sessionStorage.getItem('oanswers'));
				for(let i=0; i<ansar.length; i++) {
					let myansIndex = state.oanswers.findIndex(item => item.qid === ansar[i].qid);
					if(state.oanswers[myansIndex]) {
						Vue.set(state.oanswers, myansIndex, {qid: ansar[i].qid, response: ansar[i].response});
					}
				}
			}
		},
	},
	actions: {},

});

Vue.mixin({
	methods: {
		numdisplay: function (num) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		numdisplayNdp: function (num, numberOfDecplc) {
			return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		intToYes: function (str) {
			if(str) {
				if(parseInt(str) === 1) return "Yes";
			}
			return '';
		},
		zeroToBlank(str) {
			if(parseInt(str) === 0) {
				return '';
			}
			return str;
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			var a = new Date(unix_timestamp * 1000);
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();

			var timestr = "";
			if (includeTime === true) {
				var hour = a.getHours();
				var min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// var sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		ntobr(str) {
			return str.replaceAll("\n", "<br/>");
		},
		areArraysIdentical(arrA, arrB) {
			if (arrA === arrB) return true;
			if (arrA == null || arrB == null) return false;
			if (arrA.length !== arrB.length) return false;
			arrA.sort();
			arrB.sort();
			// If you don't care about the order of the elements inside the array, you should sort both arrays here.
			// Please note that calling sort on an array will modify that array. You might want to clone your array first.
			for (var i = 0; i < arrA.length; ++i) {
				if (arrA[i] !== arrB[i]) return false;
			}
			return true;
		},
		validateAnswer(question) {
			let aq = this.$store.state.answers.find(item => item.qid === question.qid);
			let emailqid = 0;
			if(this.$store.state.surveyOb.emailq > 0) {
				emailqid = this.$store.state.surveyOb.emailq;
			}
			if(this.$store.state.qTypeUsesArray.indexOf(question.type) > -1) {
				if (aq.selections.length > 0) return true;
			}
			else if(question.qid === emailqid) {
				if(aq.selections !== '' && /.+@.+\..+/.test(aq.selections))	{
					return true; //valid email address
				}
			}
			else if(aq.selections !== '') { //normal text answer required
				return true;
			}
			return false;
		},
		getAnswersAsArray(question) {
			let aq = this.$store.state.answers.find(item => item.qid === question.qid);
			if(this.$store.state.qTypeUsesArray.indexOf(question.type) > -1) {
				return aq.selections;
			}
			else {
				return [aq.selections]; //return string/int as array with one element
			}
		},
		prepareOptions(question) {
			let temp = question.options;
			let dontrandarr = this.splitpipe(question.dontRandOptIds);
			//randomise options if required
			if(question.randopts === 1) {
				let currentIndex = temp.length, randomIndex;
				while (currentIndex !== 0) { // While there remain elements to shuffle...
					randomIndex = Math.floor(Math.random() * currentIndex); // Pick a remaining element...
					currentIndex--;
					// And swap it with the current element.
					[temp[currentIndex], temp[randomIndex]] = [temp[randomIndex], temp[currentIndex]];
				}
				let dontrandopts = question.options.filter(opt => dontrandarr.includes(opt.optid));
				for(let lp=0; lp<dontrandopts.length; lp++) {
					let dontrandoptid = dontrandopts[lp].optid;
					let indexOfOption = temp.findIndex(elem => elem.optid === dontrandoptid);
					let targetIndex = dontrandoptid - 1;
					let myoption = temp[indexOfOption];
					temp.splice(indexOfOption, 1); //remove the item from it's old location
					temp.splice(targetIndex, 0, myoption); //move to the desired location
				}
			}
			//if this has a parent checkbox question (to hide any non-selected options), handle that first
			if(question.parentID > 0) { //make sure any 'locked' options, and Other option stays in as well
				let parentQAnswers = this.getAnswersAsArray(this.$store.state.surveyOb.questions.find(item => item.qid === question.parentID));
				temp = temp.filter(item => parentQAnswers.includes(item.optid) || dontrandarr.includes(item.optid) || question.oTextOpts === item.optid);
			}
			return temp;
		},
		stripTagsFromString(str) {
			return str.replace(/(<([^>]+)>)/gi, "");
		},
		clearLocalStorage() {
			window.sessionStorage.removeItem('answers');
			window.sessionStorage.removeItem('oanswers');
			window.sessionStorage.removeItem('respId');
			window.sessionStorage.removeItem('respToken');
			window.sessionStorage.removeItem('wlSessToken');
			window.sessionStorage.removeItem('activePageNo');
		},
		splitpipe(valstr) {
			let ret = [];
			if(valstr !== "") {
				if(isNaN(valstr)) {
					if (valstr.indexOf("|") > 0) {
						var temp = valstr.split("|");
						for(var i=0; i<temp.length; i++) {
							var ti = parseInt(temp[i]);
							ret.push(ti);
						}
					}
					else {
						ret.push(parseInt(valstr));
					}
				}
				else {
					ret.push(valstr);
				}
			}
			return ret;
		},
		cleanMSWord(text) {
			var s = text;
			s = s.replace(/[\u2018|\u2019|\u201A]/g, "'"); // smart single quotes and apostrophe
			s = s.replace(/[\u201C|\u201D|\u201E]/g, "\""); // smart double quotes
			s = s.replace(/\u2026/g, "..."); // ellipsis
			s = s.replace(/[\u2013|\u2014]/g, "-"); // dashes
			s = s.replace(/\u02C6/g, "^"); // circumflex
			s = s.replace(/\u2039/g, ""); // open angle bracket
			s = s.replace(/[\u02DC|\u00A0]/g, " "); // spaces
			return s;
		}
	},
});

//initialise app
new Vue({
	router,
	store: store,
	render: h => h(App),
}).$mount('#app')
