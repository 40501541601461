<template>
	<div class="rrams-validator">
		<div class="nav-validator" :class="{success : everythingIsOK}" @click="$emit('showoverlay')">
			<p class="valid-head">Live Validation</p>
			<p class="valid-p" v-if="everythingIsOK">All OK!</p>
			<p class="valid-p" v-if="missingOverallCumes.length > 0">Overall Cume Mismatch</p>
			<p class="valid-p" v-if="mandatoriesWithInvalid.length > 0">Mandatories Missing Answers</p>
		</div>

		<div class="overlay" v-if="overlayshown">

			<div class="overlay-box">
				<div class="overlay-topbar" style="">
					<p class="topbartext">RRAMS Validator</p>
					<span style="cursor: pointer;" @click="$emit('hideoverlay')"><b-icon class="overlay-close-icon" icon="x"></b-icon></span>
				</div>

				<div class="overlay-body">

					<div v-if="missingOverallCumes.length > 0">
						<p class="red">Please check your Overall Cume response - dayparts have some stations checked that aren't selected in Overall Cume: <br/></p>
						<ul>
							<li v-for="mid in missingOverallCumes" :key="mid">{{workdayQ.options.find(item => item.optid === mid).opttext}}</li>
						</ul>

						<p class="pagejumper" @click="emitPageChange(survey.questions.find(item => item.qid === assignedQs.cumestart).pageNo)">Go To Page</p>
					</div>

					<table class="caticheckstntbl" style="margin-top: 15px;">
						<thead><tr><td>Daypart</td><td>Station Most</td><td>Cume Stations</td></tr></thead>
						<tbody>

							<tr>
								<td class="boldme">Overall</td>
								<td><p v-for="n in selectedItems.statmost" :key="n.optid">{{n.opttext}}</p></td>
								<td><p v-for="n in selectedItems.cumestart" :key="n.optid">{{n.opttext}}</p></td>
							</tr>
							<tr>
								<td class="boldme">Breakfast</td>
								<td><p v-for="n in selectedItems.breakfast" :key="n.optid">{{n.opttext}}</p></td>
								<td><p v-for="n in selectedItems.bfcumestart" :key="n.optid">{{n.opttext}}</p></td>
							</tr>
							<tr>
								<td class="boldme">Morning</td>
								<td><p v-for="n in selectedItems.morning" :key="n.optid">{{n.opttext}}</p></td>
								<td><p v-for="n in selectedItems.morncumestart" :key="n.optid">{{n.opttext}}</p></td>
							</tr>
							<tr>
								<td class="boldme">Afternoon</td>
								<td><p v-for="n in selectedItems.afternoon" :key="n.optid">{{n.opttext}}</p></td>
								<td><p v-for="n in selectedItems.arvocumestart" :key="n.optid">{{n.opttext}}</p></td>
							</tr>
							<tr>
								<td class="boldme">Drive</td>
								<td><p v-for="n in selectedItems.drive" :key="n.optid">{{n.opttext}}</p></td>
								<td><p v-for="n in selectedItems.drivecumestart" :key="n.optid">{{n.opttext}}</p></td>
							</tr>
							<tr>
								<td class="boldme">Evenings</td>
								<td><p v-for="n in selectedItems.night" :key="n.optid">{{n.opttext}}</p></td>
								<td><p v-for="n in selectedItems.nightcumestart" :key="n.optid">{{n.opttext}}</p></td>
							</tr>
							<tr>
								<td class="boldme">Weekends</td>
								<td><p v-for="n in selectedItems.weekend" :key="n.optid">{{n.opttext}}</p></td>
								<td><p v-for="n in selectedItems.wkndcumestart" :key="n.optid">{{n.opttext}}</p></td>
							</tr>
							<tr>
								<td class="boldme">Workday</td>
								<td><p v-for="n in selectedItems.workday" :key="n.optid">{{n.opttext}}</p></td>
								<td><p v-for="n in selectedItems.workcumestart" :key="n.optid">{{n.opttext}}</p></td>
							</tr>

					</tbody></table>

					<div>
						<p style="font-weight: bold; margin-top: 10px;">Mandatories</p>
						<p v-for="mq in mandatoriesWithInvalid" :key="mq.qid" class="mand-line">
							<span class="textlinker" @click="emitPageChange(mq.pageNo)">
								Q{{mq.qid}} {{survey.questions.find(item => item.qid === mq.qid).colcode}} does not have a value selected
							</span>
						</p>
					</div>

					<div class="buttons" v-if="everythingIsOK">
						<div class="btn btn-animated" @click="$emit('finalise'); finalising = true;">
							<span v-bind:class="{'button-is-working': finalising }">
								<span>Finalise</span>
								<WorkingButtonInsert v-if="finalising"></WorkingButtonInsert>
							</span>
						</div>
					</div>
					<div class="buttons" v-else>
						<p style="font-size:15px; font-weight: bold; margin-top: 20px; color: #dc3545;">
							This survey is not ready to be finalised yet.
						</p>
					</div>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
export default {
	name: "RRAMSValidator",
	components: {WorkingButtonInsert},
	props: {
		overlayshown: Boolean,
	},
	data: function () {
		return {
			// overlayshown: false,
			finalising: false,
		}
	},
	computed: {
		survey() {
			return this.$store.state.surveyOb;
		},
		assignedQs() {
			return this.survey.rrAssignedQ;
		},
		// answers() {
		// 	return this.$store.state.answers;
		// },
		dqvalsarr() {
			return this.assignedQs.dqvalArr;
		},
		workdayQ() {
			return this.survey.questions.find(item => item.qid === this.assignedQs.workday);
		},
		overallCumeAnswerVals() {
			let arr = [];
			arr = this.getAnsAndAddToArray(arr, 'cumestart');
			arr = this.getAnsAndAddToArray(arr, 'statmost');
			return arr;
		},
		daypartCumeAnswerVals() {
			let arr = [];
			arr = this.getAnsAndAddToArray(arr, 'breakfast');
			arr = this.getAnsAndAddToArray(arr, 'bfcumestart');
			arr = this.getAnsAndAddToArray(arr, 'morning');
			arr = this.getAnsAndAddToArray(arr, 'morncumestart');
			arr = this.getAnsAndAddToArray(arr, 'afternoon');
			arr = this.getAnsAndAddToArray(arr, 'arvocumestart');
			arr = this.getAnsAndAddToArray(arr, 'drive');
			arr = this.getAnsAndAddToArray(arr, 'drivecumestart');
			arr = this.getAnsAndAddToArray(arr, 'night');
			arr = this.getAnsAndAddToArray(arr, 'nightcumestart');
			arr = this.getAnsAndAddToArray(arr, 'weekend');
			arr = this.getAnsAndAddToArray(arr, 'wkndcumestart');
			arr = this.getAnsAndAddToArray(arr, 'workday');
			arr = this.getAnsAndAddToArray(arr, 'workcumestart');
			return arr;
		},
		missingOverallCumes() {
			let missing = [];
			for(var tc=0; tc<this.daypartCumeAnswerVals.length; tc++) {
				var thisval = this.daypartCumeAnswerVals[tc];
				if(this.overallCumeAnswerVals.includes(thisval) === false && this.dqvalsarr.includes(thisval) === false) {
					if(missing.includes(thisval) === false) {
						missing.push(thisval);
					}
				}
			}
			return missing;
		},
		everythingIsOK() {
			if(this.missingOverallCumes.length === 0 && this.mandatoriesWithInvalid.length === 0) return true;
			return false;
		},
		selectedItems() {
			let p = {};
			p.statmost = this.getSelectedOptionsListFromQ('statmost');
			p.cumestart = this.getSelectedOptionsListFromQ('cumestart');
			p.breakfast = this.getSelectedOptionsListFromQ('breakfast');
			p.bfcumestart = this.getSelectedOptionsListFromQ('bfcumestart');
			p.morning = this.getSelectedOptionsListFromQ('morning');
			p.morncumestart = this.getSelectedOptionsListFromQ('morncumestart');
			p.afternoon = this.getSelectedOptionsListFromQ('afternoon');
			p.arvocumestart = this.getSelectedOptionsListFromQ('arvocumestart');
			p.drive = this.getSelectedOptionsListFromQ('drive');
			p.drivecumestart = this.getSelectedOptionsListFromQ('drivecumestart');
			p.night = this.getSelectedOptionsListFromQ('night');
			p.nightcumestart = this.getSelectedOptionsListFromQ('nightcumestart');
			p.weekend = this.getSelectedOptionsListFromQ('weekend');
			p.wkndcumestart = this.getSelectedOptionsListFromQ('wkndcumestart');
			p.workday = this.getSelectedOptionsListFromQ('workday');
			p.workcumestart = this.getSelectedOptionsListFromQ('workcumestart');
			return p;
		},
		mandatoriesWithInvalid() {
			let mq = this.survey.questions.filter(item => item.mandatory === 'Yes');
			let errq = [];
			for(let a=0; a<mq.length; a++) {
				let myq = mq[a];
				let ansraw = this.$store.state.answers.find(item => item.qid === myq.qid);
				if(!ansraw.selections || ansraw.selections === "" || ansraw.selections.length === 0) {
					errq.push(myq);
				}
			}
			return errq;
		},
	},
	methods: {
		emitPageChange(toPageNo) {
			this.overlayshown = false;
			this.$emit('pagechangeto', toPageNo);
		},
		getSelectedOptionsListFromQ(assignedStrName) {
			let myq = this.survey.questions.find(item => item.qid === this.assignedQs[assignedStrName]);
			let optarr = [];
			if(myq) {
				let ansar = this.getAnswersAsArray(myq);
				for(let a=0; a<ansar.length; a++) {
					let mid = parseInt(ansar[a]);
					if(mid > 0 && !isNaN(mid)) {
						let myopt = this.workdayQ.options.find(item => item.optid === mid);
						if (optarr.find(item => item.optid === myopt.optid) === undefined) {
							optarr.push(myopt);
						}
					}
				}
			}
			return optarr;
		},
		getAnsAndAddToArray(existingarr, assignedStrName) {
			let myq = this.survey.questions.find(item => item.qid === this.assignedQs[assignedStrName]);
			if(myq) {
				let ansar = this.getAnswersAsArray(myq);
				for(var i=0; i<ansar.length; i++) {
					let myval = parseInt(ansar[i]);
					if(myval > 0 && !isNaN(myval)) {
						if (existingarr.indexOf(myval) === -1) {
							existingarr.push(myval);
						}
					}
				}
			}
			return existingarr;
		}
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.rrams-validator {
	height: 100%;
	margin-left: 20px;
}
.nav-validator {
	/*background-color: #dc3545;*/
	background-color: #23a6ee;
	color: #FFF;
	padding: 8px 16px;
	height: 100%;
	cursor: pointer;
	width: 200px;
	transition: background-color 0.2s ease-in-out;
}
.nav-validator:hover {
	/*background-color: #ec4555;*/
	background-color: #33b6fe;
}
.nav-validator.success {
	background-color: #28a745;
}
.nav-validator.success:hover {
	background-color: #38b755;
}
.valid-head {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 11px;
	margin-bottom: 3px;
}
.valid-p {
	font-size: 10px;
}
.pagejumper {
	cursor: pointer;
	color: #FFF;
	background-color: #23A6EE;
	padding: 3px 5px;
	font-size: 10px;
	display: inline-block;
	border-radius: 5px;
}
.pagejumper:hover {
	text-decoration: underline;
	background-color: #33b6fE;
}
.caticheckstntbl {
	border: 1px solid #CCC;
}
.caticheckstntbl thead td {
	font-weight: bold;
}
.caticheckstntbl td {
	padding: 5px 10px;
	border: 1px solid #CCC;
}
.mand-line {
	font-size: 13px;
}
.red {
	color: #dc3545;
}
.textlinker {
	cursor: pointer;
}
.textlinker:hover {
	text-decoration: underline;
}
.overlay-box { /* override style.css globals */
	width: 800px;
}
.btn {
	color: #FFF;
	font-weight: bold;
	cursor: pointer;
	background-color: #23A6EE;
	width: 140px;
	display: block;
	padding: 12px 10px 10px;
	margin: 30px 30px 30px 0;
	float: left;
	text-align: center;
	transition: color 0.5s, transform 0.2s, background-color 0.2s;
	outline: none;
	overflow: hidden;
	box-shadow: none;
	position: relative;
}
</style>