<template>
	<div id="app" :class="{iscati : $store.state.isCATI}">
		<AlertTop v-bind:obj="$store.state.kalert"></AlertTop>

		<router-view></router-view>

	</div>
</template>

<script>
import AlertTop from "@/components/AlertTop";

export default {
	name: 'App',
	components: {
		AlertTop
	},
	data: function () {
		return {

		}
	},
	computed: {
		urlroot() {
			return this.$store.state.urlroot;
		},
	},
	methods: {

	},
	created() {
		document.title = 'Loading survey...';
		let urlparams = this.$route.params; //incoming router params (not GET query)
		if(urlparams.survid !== undefined && parseInt(urlparams.survid) !== 0) {
			this.$store.commit('setSurvId', urlparams.survid);
		}
		else { //no survey ID has been passed
			window.location = "https://yourwavelength.com";
			return false;
		}
		let urlgets = this.$route.query; //incoming GET parameters
		if(urlgets.sesstoken) {
			this.$store.commit('setWLSessToken', urlgets.sesstoken);
			//redirect/reload to clean the sesstoken from window URL
			let prps = [];
			for(let propname in urlgets) {
				if(propname.length > 0 && Object.prototype.hasOwnProperty.call(urlgets, propname)) {
					if(propname !== 'sesstoken') {
						prps.push(propname + "=" + urlgets[propname]);
					}
				}
			}
			window.location = this.$route.path + "?" + prps.join("&");
			return false;
		}
		else { //do we have a WL session token in localstorage
			if(window.sessionStorage.getItem('wlSessToken')) {
				this.$store.commit('setWLSessToken', window.sessionStorage.getItem('wlSessToken'));
				urlgets.sesstoken = window.sessionStorage.getItem('wlSessToken'); //add for later use
			}
		}
		this.$store.commit('setGetParams', urlgets);
	},
	mounted() {

	},
	watch: {
		$route() { //on page URL change
			let urlparams = this.$route.params; //incoming router params (not GET query)
			if(urlparams.survid !== undefined && parseInt(urlparams.survid) !== 0) {
				this.$store.commit('setSurvId', urlparams.survid);
			}
			//force close alert on page change
			this.$store.commit('closeKalert');
		}
	},
}
</script>

<style>
#app {

}
</style>
