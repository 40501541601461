<template>
	<div class="music">
		<p class="question">
			Please listen to the song.
		</p>
		<p style="font-size: 12px; margin-top: 10px;">
			If the clip does not play automatically, please click the Play button on the player below.<br/>
			If your audio player shows an error or no audio plays, please reload the page (your survey progress will not be lost)</p>
		<br/>
		<br/>
		<br/>
		<div class="mainplayer">
			<audio v-if="!isSaving" controls="controls" autoplay><source :src="fileFullUrl" type="audio/mpeg" /></audio>
		</div>

		<br />

		<div class="hookvotediv">
			<div v-if="allowVoting === false" class="hookvoteblanket">Please listen to the song...</div>
			<div v-if="allowVoting">

				<div style="margin: 10px 0 10px">
					<p class="question">Do you know this song?</p>
					<button type="button" @click="unfamVote = 0" :class="{selected : unfamVote === 0}"><span class="boldme">Yes</span></button>
					<button type="button" @click="unfamVote = 1" :class="{selected : unfamVote === 1}"><span class="boldme">No</span></button>
				</div>

				<div v-if="unfamVote !== null" style="padding: 20px 0 0">
					<p class="question">How would you rate this song?</p>
					<button type="button" class="love" @click="musicVote = 'Love'" :class="{selected : musicVote === 'Love'}"><span class="boldme">I LOVE it</span></button>
					<button type="button" class="like" @click="musicVote = 'Like'" :class="{selected : musicVote === 'Like'}"><span class="boldme">I LIKE it</span></button>
					<button type="button" class="ok" @click="musicVote = 'OK'" :class="{selected : musicVote === 'OK'}"><span class="boldme">It's OK</span></button>
					<button type="button" class="dislike" @click="musicVote = 'Dislike'" :class="{selected : musicVote === 'Dislike'}"><span class="boldme">I DISLIKE it</span></button>
					<button type="button" class="hate" @click="musicVote = 'Hate'" :class="{selected : musicVote === 'Hate'}"><span class="boldme">I HATE it</span></button>

					<br/><br/>

					<div v-if="unfamVote === 0">
						<p class="question">Are you tired of this song?</p>
						<button type="button" @click="tiredVote = 1" :class="{selected : tiredVote === 1}"><span class="boldme">Yes</span></button>
						<button type="button" @click="tiredVote = 0" :class="{selected : tiredVote === 0}"><span class="boldme">No</span></button>
						<br/><br/>
					</div>

					<p class="question">Is this a song you would expect to hear on your favourite radio station?</p>
					<button type="button" @click="fitVote = 1" :class="{selected : fitVote === 1}"><span class="boldme">Yes</span></button>
					<button type="button" @click="fitVote = 0" :class="{selected : fitVote === 0}"><span class="boldme">No</span></button>

					<br/><br/>

					<span class="ycbtn" @click="sendMusicVotes">Next</span>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "BauerAbsMagMusic2020",
	components: {},
	props: {
		hookid: Number,
	},
	data: function () {
		return {
			isSaving: false,
			allowVoting: false,

			musicVote: null,
			tiredVote: null,
			unfamVote: null,
			fitVote: null,
		}
	},
	computed: {
		fileFullUrl() {
			return "https://yourwavelength.com/hooklib/"+this.hookid+".mp3";
		},
		validAnswers() {
			if(this.musicVote === null || this.fitVote === null || this.unfamVote === null) return false;
			if(this.unfamVote === 0 && this.tiredVote === null) return false;
			return true;
		},
	},
	methods: {
		sendMusicVotes() {
			if(this.validAnswers === false) {
				window.alert("Please ensure all questions have been answered")
				return false;
			}
			let self = this;
			if(this.tiredVote === null) this.tiredVote = 0;
			let formdata = {
				survid: this.$store.state.survid,
				respid: this.$store.state.respId,
				hookid: this.hookid,
				vote: this.musicVote,
				tiredInt: this.tiredVote,
				fitInt: this.fitVote,
				unfamInt: this.unfamVote,
			};
			if(this.hookid > 0 && this.$store.state.survid.length > 0 || this.$store.state.respId.length > 0) {
				self.isSaving = true;
				axios.post(
					self.$store.state.urlroot + "surveys/post-collect/save-hook-answer.php", formdata
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.respStatus) {
						self.respStatus = ret.respStatus;
						self.handleRespStatus();
					}
					if (ret.savedHookId) {
						self.$emit('gotonext', ret.savedHookId);
						self.isSaving = false;
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		resetVotes() {
			this.musicVote = null;
			this.tiredVote = null;
			this.unfamVote = null;
			this.fitVote = null;
		},
		blanketTimeout() {
			setTimeout(function() {
				this.allowVoting = true;
			}.bind(this), 5000); //do not allow voting for first five seconds
		},
	},
	watch: {
		hookid() {
			this.allowVoting = false;
			this.resetVotes();
			this.blanketTimeout();
		},
	},
	mounted() {
		this.resetVotes();
		this.blanketTimeout();
	}
}
</script>

<style scoped>
.question {
	font-size: 16px;
	font-weight: bold;
}
.mainplayer {
	height: 60px;
}
.mainplayer audio {
	max-width: 100%;
}
.hookvotediv {
	width: 100%;
	position: relative;
}
.hookvoteblanket {
	background-color: #FFF;
	z-index: 9999;
	width: 100%;
	min-height: 250px;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: left;
	align-items: flex-start;
	text-align: left;
	font-size: 14px;
	padding-top: 50px;
}
.hookvotediv button {
	display: block;
	padding: 8px 10px;
	margin: 6px 0;
	border: 3px #FFFFFF solid;
	color: #FFF;
	text-align: left;
	width: 250px;
	cursor: pointer;
	background-color: #5a2984;
	transition: all 0.2s ease-in-out;
}
.hookvotediv button:hover {
	border: 3px #424281 solid;
	background-color: #444444 !important;
}
.hookvotediv button.selected {
	border: 3px #23A6EE solid;
	background-color: #444444 !important;
}
.ycbtn {
	border-radius: 6px;
	cursor: pointer;
	color: #FFF;
	font-weight: bold;
	text-decoration: none;
	transition: background 0.2s linear;
	font-size: 14px;
	margin: 10px 0 20px 0;
	background-color: #5a2984;
	width: 140px;
	display: block;
	padding: 12px 10px 10px;
	float: left;
	text-align: center;
	outline: none;
	overflow: hidden;
	box-shadow: none;
	position: relative;
}
.ycbtn:hover {
	background-color: #9a69B4 !important;
}
.skin-bauermagic .hookvotediv button,
.skin-bauermagic .ycbtn {
	background-color: #003C82;
}
.skin-bauermagic .ycbtn:hover {
	background-color: #407CB2 !important;
}
.skin-bauercountry .hookvotediv button,
.skin-bauercountry .ycbtn {
	background-color: #952d98;
}
.skin-bauercountry .ycbtn:hover {
	background-color: #C56dD8 !important;
}

@media screen and (max-width: 660px) {
	.ycbtn {
		width: 100%;
	}
}
</style>