<template>
	<div class="navq" :class="{activeQ : activepageno === question.pageNo}" :title="question.qtext" @click="$emit('pagechangeto', question.pageNo)">
		<span class="title">
			{{nicetext}}
			<span v-if="question.mandatory === 'Yes'">*</span>
		</span>
		<span class="icon-holder">
			<i v-if="hasValidAnswer" class="mdi mdi-check-bold hasanswers"></i>
			<i v-if="hasInvalidAnswer" class="mdi mdi-exclamation-thick exclam"></i>
		</span>
	</div>
</template>

<script>
export default {
	name: "CATINavQuestion",
	components: {},
	props: {
		question: Object,
		activepageno: Number,
	},
	data: function () {
		return {}
	},
	computed: {
		nicetext() {
			if(this.question.colcode) return this.question.colcode;
			let myqtext = this.question.qtext;
			if(this.question.type === 'MatrixHeadRadio' || this.question.type === 'MatrixHeadCheck') {
				let arr = this.question.qtext.split("|");
				myqtext = arr[0];
			}
			return this.stripTagsFromString(myqtext).substr(0,30);
		},
		hasValidAnswer() {
			return this.validateAnswer(this.question);
		},
		hasInvalidAnswer() {
			// if(this.question.mandatory === 'Yes' && !this.hasValidAnswer) {
			// 	return true;
			// }
			return false;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.navq {
	border-bottom: 2px solid #FFF;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	color: #000;
	font-size: 13px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.navq:hover:not(.activeQ) {
	background-color: #CCC;
}
.activeQ {
	background-color: #23A6EE;
	color: #FFF;
}
.title {
	width: calc(100% - 50px);
	padding-top: 7px;
	padding-bottom: 7px;
}
.icon-holder {
	width: 40px;
	justify-self: flex-end;
	text-align: right;
}
.hasanswers {
	font-size: 16px;
	color: #0B0;
}
.exclam {
	font-size: 18px;
	color: #B00;
}
</style>