<template>
	<div>
		<input :type="intype" class="textbox" v-model="selections" ref="ta" />

<!--		<div>{{storedAnswersForQuestion}}</div>-->
	</div>
</template>

<script>
export default {
	name: "TextQ",
	components: {},
	props: {
		question: Object,
		type: String, //optional eg 'number'
	},
	data: function () {
		return {}
	},
	computed: {
		intype() {
			if(this.type) { //set as 'number' etc
				return this.type;
			}
			return 'text';
		},
		storedAnswersForQuestion() {
			if (this.$store.state.answers.find(item => item.qid === this.question.qid)) {
				return this.$store.state.answers.find(item => item.qid === this.question.qid);
			}
			return [];
		},
		isFirstQuestionOnPage() {
			let qsOnPage = this.$store.state.surveyOb.questions.filter(item => item.pageNo === this.question.pageNo);
			if(qsOnPage[0].qid === this.question.qid) {
				return true;
			}
			return false;
		},
		selections: {
			get() {
				let ob = this.$store.state.answers.find(item => item.qid === this.question.qid);
				return ob.selections;
			},
			set(value) {
				this.$store.commit('updateAnswer', {qid: this.question.qid, selections: this.cleanMSWord(value)});
			}
		},
	},
	methods: {

	},
	watch: {},
	mounted() {
		setTimeout(function() {
			if(this.$refs['ta'] && this.isFirstQuestionOnPage) {
				this.$refs['ta'].focus();
			}
		}.bind(this), 250);
	}
}
</script>

<style scoped>
.textbox {
	font-size: 14px;
	color: #444;
	background-color: #FFFFFF;
	border: 1px solid #D4D8DB;
	width: 100%;
	max-width: 900px;
	margin: 0 5px 0 0;
	padding: 8px;
}
</style>