<template>
	<div class="pct-hold">
		<div class="pct">
			<div class="pctin" :style="'width:'+pctval+'%;'"></div>
		</div>

		<div class="pcttext">
			{{pctval}}% complete
		</div>
	</div>
</template>

<script>
export default {
	name: "PercentageComplete",
	components: {},
	props: {
		activepageno: Number,
	},
	data: function () {
		return {}
	},
	computed: {
		pctval() {
			if(this.activepageno > 0 && this.$store.state.surveyOb.lastPageNo > 0) {
				let numansweredpages = this.activepageno + this.$store.getters.numHooksAnswered;
				let numtotpages = this.$store.state.surveyOb.lastPageNo + this.$store.state.surveyOb.hookcount;
				let pct = Math.round(numansweredpages / numtotpages * 100);
				if(pct > 99) return 99;
				return pct;
			}
			return 0;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.pct-hold {
	padding: 0 30px;
	position: relative;
}
.pct {
	height: 25px;
	background: #E0E0E0;
	border-radius: 5px;
	position: relative;
	margin-top: 14px;
}
.pctin {
	height: 25px;
	margin-top: 0;
	background-color: #23A6EE;
	border-radius: 5px 0 0 5px;
	position: absolute;
	transition: all 0.2s linear;
}
.pcttext {
	text-align: right;
	font-size: 10px;
	padding: 5px 0;
	width: 100%;
	position: absolute;
	color: #FFF;
	margin: 0;
	right: 30px;
}
</style>