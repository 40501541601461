<template>
	<div>
		<div v-if="$store.state.isCATI">
			<br/>
			<div @click="clearAnswers" class="clear-answers">Clear Answer</div>
			<br/>
		</div>
		<div>
			<div v-for="opt in options" :key="opt.optid" class="radio-option-holder" :class="{selected : selections === opt.optid, hasOText : parseInt(question.oTextOpts) === opt.optid}" >
				<div class="radio-option" @click="setAnswer(opt.optid)">
					<div class="radio-option-inside">
						<div class="inbit">
							<input :class="{noshow : $store.state.isCATI && parseInt(question.oTextOpts) !== opt.optid}" type="radio" v-model="selections" :value="opt.optid" />
							<span class="inplabel">{{opt.opttext}}</span>
						</div>
						<textarea v-if="parseInt(question.oTextOpts) === opt.optid" v-model="otextinput"></textarea>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "RadioQ",
	components: {

	},
	props: {
		question: Object,
	},
	data: function () {
		return {

		}
	},
	computed: {
		options() {
			return this.prepareOptions(this.question);
		},
		storedAnswersForQuestion() {
			if (this.$store.state.answers.find(item => item.qid === this.question.qid)) {
				return this.$store.state.answers.find(item => item.qid === this.question.qid);
			}
			return [];
		},
		selections: {
			get() {
				let ob = this.$store.state.answers.find(item => item.qid === this.question.qid);
				return ob.selections;
			},
			set(value) {
				this.$store.commit('updateAnswer', {qid: this.question.qid, selections: value});
			}
		},
		otextinput: {
			get() {
				let ob = this.$store.state.oanswers.find(item => item.qid === this.question.qid);
				return ob.response;
			},
			set(value) {
				this.$store.commit('setOAns', {qid: this.question.qid, response: this.cleanMSWord(value)});
			}
		},
	},
	methods: {
		setAnswer(optid) {
			this.selections = optid;
			if(this.selections !== parseInt(this.question.oTextOpts)) {
				this.otextinput = "";
			}
			if (this.$store.state.isCATI && this.selections !== '' && parseInt(this.question.oTextOpts) !== optid) { //CATI push to 'next' when radio (single choice) question answer is selected
				this.$emit('nextpage');
			}
		},
		clearAnswers() { //CATI add a 'clear answer' button for single choice questions
			this.selections = '';
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.radio-option-holder {
	display: inline-block;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	margin-bottom: 10px;
	transition: all 0.2s ease-in-out;
}
.radio-option {
	width: 250px;
	padding: 5px 10px;
	background-color: #EFEFEF;
	border: 1px	solid #CCC;
	height: 60px;
	overflow: hidden;
	margin: 8px;
	cursor: pointer;
}
.radio-option-inside {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.radio-option-holder:hover, .radio-option-holder.selected {
	background-color: #23A6EE;
}
.hasOText {
	display: block;
	width: 80%;
}
.hasOText .radio-option {
	width: calc(100% - 16px);
	display: inline-block;
	height: auto;
}
.hasOText .radio-option-inside {
	display: block;
	text-align: left;
	margin-top: 10px;
}
.hasOText .radio-option-inside span {
	margin-left: 10px;
}
.hasOText .radio-option-inside textarea {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
	height: 50px;
	border: 1px solid #CCC;
	padding: 8px;
}
.noshow {
	display: none;
}
.clear-answers {
	color: #B00;
	font-size: 12px;
	cursor: pointer;
}
.clear-answers:hover {
	text-decoration: underline;
}

/* overrides for normal non cati view */
.notcatiview .radio-option-holder {
	display: block;
	margin: 0;
	border: 1px solid #D4D8DB;
	border-top: none;
}
.notcatiview .radio-option-holder:first-of-type {
	border-top: 1px solid #D4D8DB;
}
.notcatiview .radio-option {
	display: flex;
	width: unset;
	margin: 0;
	background-color: #FFF;
	height: unset;
	border: none;
	transition: background-color 200ms linear;
}
.notcatiview .radio-option:hover,
.notcatiview .radio-option-holder.selected .radio-option {
	background-color: #23A6EE;
	font-weight: normal;
	color: #FFF;
}
.notcatiview .radio-option .radio-option-inside {
	padding: 6px 5px;
	text-align: left;
	justify-content: unset;
}
.notcatiview .hasOText .radio-option .radio-option-inside {
	width: 100%;
}
.notcatiview .radio-option input {
	height: 2em;
	width: 2em;
	margin: 0;
}
.notcatiview .inbit {
	display: flex;
	align-items: center;
}
.notcatiview .radio-option .inplabel {
	margin-left: 10px;
}
.notcatiview .hasOText {
	width: 100%;
}

</style>