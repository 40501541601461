<template>
	<div>
		<div>
			<div v-for="opt in options" :key="opt.optid" class="check-option-holder" :class="{selected : selections.includes(opt.optid), hasOText : parseInt(question.oTextOpts) === opt.optid}" >
				<div class="check-option" >
					<div class="check-option-inside" @click="toggleAnswer(opt.optid)">
						<input type="checkbox" v-model="selections" :value="opt.optid" />
						<span class="inplabel">{{opt.opttext}}</span>
					</div>
					<textarea v-if="parseInt(question.oTextOpts) === opt.optid" v-model="otextinput"></textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "CheckBoxQ",
	components: {

	},
	props: {
		question: Object,
	},
	data() {
		return {

		}
	},
	computed: {
		options() {
			return this.prepareOptions(this.question);
		},
		storedAnswersForQuestion() {
			if (this.$store.state.answers.find(item => item.qid === this.question.qid)) {
				return this.$store.state.answers.find(item => item.qid === this.question.qid);
			}
			return [];
		},
		selections: {
			get() {
				let ob = this.$store.state.answers.find(item => item.qid === this.question.qid);
				return ob.selections;
			},
		},
		otextinput: {
			get() {
				let ob = this.$store.state.oanswers.find(item => item.qid === this.question.qid);
				return ob.response;
			},
			set(value) {
				this.$store.commit('setOAns', {qid: this.question.qid, response: this.cleanMSWord(value)});
			}
		},
	},
	methods: {
		toggleAnswer(optid) {
			if(this.selections.indexOf(optid) > -1) { //remove
				let ind = this.selections.indexOf(optid);
				this.selections.splice(ind, 1);
			}
			else {
				this.selections.push(optid);
			}
		},
	},
	watch: {
		selections() { //clear OText if the applicable 'Other' checkbox is not checked
			if(this.selections.includes(parseInt(this.question.oTextOpts)) === false) {
				this.otextinput = "";
			}
		},
	},
	mounted() {

	}
}
</script>

<style scoped>
.check-option-holder {
	display: inline-block;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	margin-bottom: 10px;
	transition: all 0.2s ease-in-out;
}
.check-option {
	width: 250px;
	background-color: #EFEFEF;
	border: 1px	solid #CCC;
	height: 60px;
	overflow: hidden;
	margin: 8px;
	cursor: pointer;
}
.check-option-inside {
	display: flex;
	padding: 5px;
	height: 100%;
	align-items: center;
}
.check-option-holder:hover, .check-option-holder.selected {
	background-color: #23A6EE;
}
.hasOText {
	display: block;
	width: 80%;
}
.hasOText .check-option {
	width: calc(100% - 16px);
	display: inline-block;
	height: auto;
}
.hasOText .check-option-inside {
	display: block;
	text-align: left;
	margin-top: 10px;
}
.hasOText .check-option textarea {
	display: block;
	margin: 10px auto;
	width: 98%;
	height: 50px;
	border: 1px solid #CCC;
	padding: 8px;
}
.check-option input {
	margin: 0 10px;
}
/* overrides for normal non cati view */
.notcatiview .check-option-holder {
	display: block;
	margin: 0;
	border: 1px solid #D4D8DB;
	border-top: none;
}
.notcatiview .check-option-holder:first-of-type {
	border-top: 1px solid #D4D8DB;
}
.notcatiview .check-option {
	display: block;
	width: unset;
	margin: 0;
	background-color: #FFF;
	height: unset;
	border: none;
	transition: background-color 200ms linear;
}
.notcatiview .check-option:hover,
.notcatiview .check-option-holder.selected .check-option {
	background-color: #23A6EE;
	font-weight: normal;
	color: #FFF;
}
.notcatiview .check-option .check-option-inside {
	padding: 11px 15px;
	width: 100%;
}
.notcatiview .check-option input {
	height: 2em;
	width: 2em;
	margin: 0;
}
.notcatiview .check-option .inplabel {
	margin-left: 10px;
}
.notcatiview .hasOText {
	width: 100%;
}
.notcatiview .hasOText .check-option-inside {
	display: flex;
}
</style>