<template>
	<div class="music">
		<p class="question">
			What do you think of the following song?
		</p>
		<p style="font-size: 12px; margin-top: 10px;">
			If the clip does not play automatically, please click the Play button on the player below.<br/>
			If your audio player shows an error or no audio plays, please reload the page (your survey progress will not be lost)</p>
		<br/>
		<br/>
		<br/>
		<div class="mainplayer">
			<audio v-if="!isSaving" controls="controls" autoplay><source :src="fileFullUrl" type="audio/mpeg" /></audio>
		</div>

		<br />

		<div class="hookvotediv">
			<div v-if="allowVoting === false" class="hookvoteblanket">Please listen to the song...</div>
			<div v-if="allowVoting">
				<button type="button" @click="vote('Love')"><span class="boldme">Love</span></button>
				<button type="button" @click="vote('Like')"><span class="boldme">Like</span></button>
				<button type="button" @click="vote('OK')">It's <span class="boldme">OK</span></button>
				<button type="button" @click="vote('Dislike')"><span class="boldme">Dislike</span></button>
				<button type="button" @click="vote('Hate')"><span class="boldme">Hate</span></button>
				<button type="button" @click="vote('Tired')"><span class="boldme">Tired Of</span></button>
				<button type="button" @click="vote('Unfam')">I <span class="boldme">Don't Know</span> this song</button>
			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "DefaultMusic",
	components: {},
	props: {
		hookid: Number,
	},
	data: function () {
		return {
			isSaving: false,
			allowVoting: false,
		}
	},
	computed: {
		fileFullUrl() {
			return "https://yourwavelength.com/hooklib/"+this.hookid+".mp3";
		},
	},
	methods: {
		vote(voteval) {
			let self = this;
			let formdata = {
				survid: this.$store.state.survid,
				respid: this.$store.state.respId,
				hookid: this.hookid,
				vote: voteval,
			};
			if(this.hookid > 0 && this.$store.state.survid.length > 0 || this.$store.state.respId.length > 0) {
				self.isSaving = true;
				axios.post(
					self.$store.state.urlroot + "surveys/post-collect/save-hook-answer.php", formdata
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.savedHookId) {
						self.$emit('gotonext', ret.savedHookId);
						self.isSaving = false;
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		blanketTimeout() {
			setTimeout(function() {
				this.allowVoting = true;
			}.bind(this), 5000); //do not allow voting for first five seconds
		},
	},
	watch: {
		hookid() {
			this.allowVoting = false;
			this.blanketTimeout();
		},
	},
	mounted() {
		this.blanketTimeout();
	}
}
</script>

<style scoped>
.question {
	font-size: 16px;
	font-weight: bold;
}
.mainplayer {
	height: 60px;
}
.mainplayer audio {
	max-width: 100%;
}
.hookvotediv {
	width: 100%;
	position: relative;
}
.hookvoteblanket {
	background-color: #FFF;
	z-index: 9999;
	width: 100%;
	min-height: 250px;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: left;
	align-items: flex-start;
	text-align: left;
	font-size: 14px;
	padding-top: 50px;
}
.hookvotediv button {
	display: block;
	padding: 8px 10px;
	margin: 6px 0;
	border: 3px #23A6EE solid;
	color: #FFF;
	text-align: left;
	width: 250px;
	cursor: pointer;
	background-color: #23A6EE;
}
</style>