<template>
	<div>
		<select class="selectbox" v-model="selections">
			<option v-for="opt in options" :key="opt.optid" :value="opt.optid">{{opt.opttext}}</option>
		</select>

<!--		<div>{{storedAnswersForQuestion}}</div>-->
	</div>
</template>

<script>
export default {
	name: "SelectOne",
	components: {},
	props: {
		question: Object,
	},
	data: function () {
		return {}
	},
	computed: {
		options() {
			let myopts = [{optid: '', opttext: '--Select--'}];
			let agechecks = ["please select your age", "could you tell me what was your age at your last birthday?", "all ages", "all years","individual years"];
			if(agechecks.includes(this.question.qtext.toLowerCase()) || agechecks.includes(this.question.options[0].opttext.toLowerCase())) {
				for(let qs=10; qs<100; qs++) {
					if(qs === 10) myopts.push({optid: qs, opttext: "10 or under"});
					else if(qs === 99) myopts.push({optid: qs, opttext: "99 or over"});
					else {
						myopts.push({optid: qs, opttext: qs});
					}
				}
			}
			else { //normal (non auto age generated question)
				let tempops = this.prepareOptions(this.question); //for any randomisation required
				for(let i=0; i<tempops.length; i++) {
					let thisOpt = tempops[i];
					if(thisOpt.opttext !== '') {
						myopts.push({optid: thisOpt.optid, opttext: thisOpt.opttext});
					}
				}
			}
			return myopts;
		},
		storedAnswersForQuestion() {
			if (this.$store.state.answers.find(item => item.qid === this.question.qid)) {
				return this.$store.state.answers.find(item => item.qid === this.question.qid);
			}
			return [];
		},
		selections: {
			get() {
				let ob = this.$store.state.answers.find(item => item.qid === this.question.qid);
				return ob.selections;
			},
			set(value) {
				this.$store.commit('updateAnswer', {qid: this.question.qid, selections: value});
			}
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.selectbox {
	padding: 10px 5px;
	width: 100%;
	max-width: 900px;
	border: 1px	solid #D4D8DB;
}
</style>