import { render, staticRenderFns } from "./MatrixRadioRow.vue?vue&type=template&id=1acb031c&scoped=true&"
import script from "./MatrixRadioRow.vue?vue&type=script&lang=js&"
export * from "./MatrixRadioRow.vue?vue&type=script&lang=js&"
import style0 from "./MatrixRadioRow.vue?vue&type=style&index=0&id=1acb031c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1acb031c",
  null
  
)

export default component.exports