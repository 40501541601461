<template>
	<div>
		<textarea v-model="selections" ref="ta"></textarea>

<!--		<div>{{storedAnswersForQuestion}}</div>-->
	</div>
</template>

<script>
export default {
	name: "TextAreaQ",
	components: {},
	props: {
		question: Object,
	},
	data: function () {
		return {}
	},
	computed: {
		storedAnswersForQuestion() {
			if (this.$store.state.answers.find(item => item.qid === this.question.qid)) {
				return this.$store.state.answers.find(item => item.qid === this.question.qid);
			}
			return [];
		},
		selections: {
			get() {
				let ob = this.$store.state.answers.find(item => item.qid === this.question.qid);
				return ob.selections;
			},
			set(value) {
				this.$store.commit('updateAnswer', {qid: this.question.qid, selections: this.cleanMSWord(value)});
			}
		},
	},
	methods: {

	},
	watch: {},
	mounted() {
		setTimeout(function() {
			if(this.$refs['ta']) {
				this.$refs['ta'].focus();
			}
		}.bind(this), 50);
	}
}
</script>

<style scoped>
textarea {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
	max-width: 900px;
	height: 90px;
	background-color: #F1F1F1;
	border: 1px solid #CCC;
	padding: 8px;
}
</style>