<template>
	<div v-if="mediatype" class="media-holder">

		<video v-if="mediatype === 'video'" width="310" height="200" controls="controls" class="html5video">
			<source :src="fileFullUrl" type="video/mp4" />
		</video>

		<audio v-if="mediatype === 'audio'" controls="controls"><source :src="fileFullUrl" type="audio/mpeg" /></audio>

		<img v-if="mediatype === 'image'" :src="fileFullUrl" alt="Image" />

	</div>
</template>

<script>
export default {
	name: "MediaEmbed",
	components: {},
	props: {
		filename: String,
	},
	data: function () {
		return {}
	},
	computed: {
		mediatype() {
			let extarr = this.filename.split(".");
			let ext = extarr[extarr.length - 1].toLowerCase();
			let audios = ['mp3'];
			let videos = ['mp4','flv','wmv','avi'];
			let image = ['jpg','jpeg','png','gif'];
			if(audios.includes(ext)) return 'audio';
			else if(videos.includes(ext)) return 'video';
			else if(image.includes(ext)) return 'image';
			return false;
		},
		fileFullUrl() {
			return this.$store.state.urlroot+"surveys/media/"+this.filename;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.media-holder {
	padding: 10px 0;
}
.media-holder img {
	max-height: 350px;
	max-width: 80%;
}
.media-holder audio {
	max-width: 90%;
}
.media-holder video {
	max-width: 80%;
	max-height: 400px;
}
</style>