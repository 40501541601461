<template>
	<div :class="{iscatiview : isCATI, notcatiview : !isCATI}">
		<ErrorBox v-if="errorType" :error_type="errorType" :error_content="error_content"></ErrorBox>

		<Loader v-if="isSaving && !isCATI"></Loader>

		<div v-if="survey && survey.questions && !errorType" :class="skinClass">
			<div class="top-section">
				<div class="top-section-inner">
					<div class="percentage-section">
						<PercentageComplete v-if="showingInfoView.length === 0" :activepageno="activePage"></PercentageComplete>
					</div>
					<div v-if="isCATI" class="cati-buttons">
						<div type="button" class="btn catifuncs-btn" @click="abortCall">Abort</div>
						<div type="button" class="btn catifuncs-btn" @click="disqualifyCall">Disqualify</div>
						<RRAMSValidator v-if="survey.isRegRatings === 1" :overlayshown="validateOverlayShown"
								v-on:pagechangeto="goToPage($event)"
								v-on:hideoverlay="validateOverlayShown = false"
								v-on:showoverlay="showCATIValidationOverlay"
								v-on:finalise="finaliseSurvey"
						></RRAMSValidator>
					</div>
				</div>
			</div>

			<BackstageHeader v-if="survey.client === 'Backstage' || survey.client === 'BackstageKIIS' || survey.client ==='BackstagePG'"></BackstageHeader>

			<div v-if="showingInfoView.length === 0 && inHooksSection === false" class="structure">
				<div v-if="isCATI && catiShowLeftQlist" class="leftnav">
					<div>
						<span class="cati-toggle-question-left" @click="catiShowLeftQlist = false">Hide Questions</span>
						<CATINavQuestion v-for="q in navQuestions" :key="q.qid"
							:question="q"
							:activepageno="activePage"
							v-on:pagechangeto="goToPage($event)"
						></CATINavQuestion>
					</div>
				</div>
				<div v-if="isCATI && !catiShowLeftQlist" >
					<span class="cati-toggle-question-left abstog" @click="catiShowLeftQlist = true">Show Questions</span>
				</div>
				<div class="survey-view">
					<b-alert :show="showCookieAlert" variant="secondary" style="margin-bottom: 20px;">
						<p>This website uses cookies to assist moving through the survey. By continuing to complete this survey you are agreeing to our use of cookies.</p>
					</b-alert>

					<b-alert :show="showLoadedFromContactListAlert" variant="success" style="margin-bottom: 20px;">
						<p>To save you time, we've automatically added some information you entered in one of our previous surveys.  If anything looks wrong feel free to change it. </p>
					</b-alert>

					<Question v-for="q in questionsWithoutSubs" :key="q.qid"
							:question="q" :activepageno="activePage" v-on:nextpage="goToNextPage"
					></Question>
					<div class="buttons" v-if="questionsWithoutSubs.length > 0">
						<div v-if="activePage > 1 && isCATI" class="btn" @click="goToPage(activePage - 1)">Prev</div>
						<div class="btn btn-animated" @click="goToNextPage">
							<span v-bind:class="{'button-is-working':(isSavingLastQPage || (isSaving && !isCATI))}">
								<span v-if="activePage < survey.lastPageNo || survey.hookcount > 0">Next</span>
								<span v-else>Finalise</span>
								<WorkingButtonInsert v-if="isSavingLastQPage || (isSaving && !isCATI)"></WorkingButtonInsert>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="showingInfoView.length === 0 && inHooksSection === true" class="structure">
				<div class="survey-view">
					<DefaultMusic v-if="musicfile === 'default'" :hookid="activeHookId" v-on:gotonext="prepareNextHook($event)"></DefaultMusic>
					<BauerHitsMusic2020 v-if="musicfile === 'music-bcn20'" :hookid="activeHookId" v-on:gotonext="prepareNextHook($event)"></BauerHitsMusic2020>
					<BauerAbsMagMusic2020 v-if="musicfile === 'music-bam2020'" :hookid="activeHookId" v-on:gotonext="prepareNextHook($event)"></BauerAbsMagMusic2020>
				</div>
			</div>
			<div v-if="showingInfoView.length > 0 && inHooksSection === false" class="structure">
				<OMTInstructions v-if="showingInfoView === 'omtinstructions'" v-on:gotohooks="goToHooks"></OMTInstructions>
				<Finish v-if="showingInfoView === 'finish'" :exiturl="exiturl_complete" :fname="fnameForFinish"></Finish>
				<FinishBauerRefer v-if="showingInfoView === 'finish-bauer-refer'" :exiturl="exiturl_complete" :fname="fnameForFinish"></FinishBauerRefer>
			</div>
<!--			<Footer></Footer>-->
		</div>
	</div>
</template>

<script>
import axios from "axios";
import CATINavQuestion from "@/components/CATINavQuestion";
import Question from "@/components/Question";
import PercentageComplete from "@/components/PercentageComplete";
import RRAMSValidator from "@/components/RRAMSValidator";
import ErrorBox from "@/components/ErrorBox";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
// import Footer from "@/components/Footer";
import Loader from "@/components/Loader";
import Finish from "@/components/Finish";
import FinishBauerRefer from "@/components/FinishBauerRefer";
import DefaultMusic from "@/components/music/DefaultMusic";
import BauerHitsMusic2020 from "@/components/music/BauerHitsMusic2020";
import BauerAbsMagMusic2020 from "@/components/music/BauerAbsMagMusic2020";
import OMTInstructions from "@/components/OMTInstructions";
import BackstageHeader from "@/components/headers/BackstageHeader";

export default {
	name: "Main",
	components: {
		BackstageHeader,
		OMTInstructions,
		BauerAbsMagMusic2020,
		BauerHitsMusic2020,
		DefaultMusic,
		FinishBauerRefer,
		Finish,
		Loader,
		// Footer,
		WorkingButtonInsert,
		ErrorBox,
		RRAMSValidator,
		PercentageComplete,
		Question,
		CATINavQuestion
	},
	props: {},
	data: function () {
		return {
			activePage: 1,
			nextPageId: null,
			respStatus: null,
			exiturl_screenout: null, //will be overridden later
			exiturl_quotafull: 'https://yourwavelength.com', //will be overridden later
			exiturl_complete: 'https://yourwavelength.com', //will be overridden later
			errorType: null,
			error_content: {
				exit_url: '',
			},
			isSaving: false,
			isSavingLastQPage: false, //will only be true when the final submit is called
			isPageChanging: false,
			hasLoadedFromContactList: false,
			inHooksSection: false,
			showingInfoView: '',
			fnameForFinish: '',

			validateOverlayShown: false,

			catiShowLeftQlist: true,
		}
	},
	computed: {
		survid() {
			return this.$store.state.survid;
		},
		survey() {
			return this.$store.state.surveyOb;
		},
		urlroot() {
			return this.$store.state.urlroot;
		},
		isCATI() {
			return this.$store.state.isCATI;
		},
		activeHookId() {
			if(this.$store.state.hookIdsRemaining && this.$store.state.hookIdsRemaining.length > 0) {
				return this.$store.state.hookIdsRemaining[0];
			}
			return false;
		},
		musicfile() {
			if(this.survey.musicfile && this.survey.musicfile.length > 0) {
				let nm = this.survey.musicfile.toLowerCase().replace(".php","");
				return nm;
			}
			return 'default';
		},
		navQuestions() {
			let noshows = ['HeaderText','MatrixSubRadio','MatrixSubCheck','MatrixEndRadio','MatrixEndCheck'];
			return this.$store.state.surveyOb.questions.filter(item => noshows.includes(item.type) === false);
		},
		questionsWithoutSubs() {
			if(this.isPageChanging) return [];
			let noshows = ['MatrixSubRadio','MatrixSubCheck','MatrixEndRadio','MatrixEndCheck'];
			return this.$store.state.surveyOb.questions.filter(item => noshows.includes(item.type) === false);
		},
		skinClass() {
			if(this.survey && this.survey.client && this.survey.client.length > 0) {
				return "skin-"+this.survey.client.toLowerCase();
			}
			return "";
		},
		showLoadedFromContactListAlert() {
			if(this.hasLoadedFromContactList === true && this.activePage === 1) return true;
			return false;
		},
		showCookieAlert() {
			if(this.activePage > 1) return false;
			let arr = ['bauerhits','bauerplace','bauerabsolute','bauermagic','bauercountry'];
			if(arr.indexOf(this.survey.client.toLowerCase()) > -1) return true;
			return false;
		},
	},
	methods: {
		getInitialLoad() {
			let self = this;
			//before we hit the server, see if we have a RespId and Token in local storage (but don't override GET IDs if passed)
			if(window.sessionStorage.getItem('respId') && window.sessionStorage.getItem('respId') !== "") { //we have a sessStore RespID
				if(this.$store.state.respId && this.$store.state.respId !== window.sessionStorage.getItem('respId')) { //mismatch between GET ID and sess ID - probably user changed the URL manually
					this.clearLocalStorage();
				}
			}
			if (window.sessionStorage.getItem('respId') && !this.$store.state.respId) {
				this.$store.commit('setRespId', window.sessionStorage.getItem('respId'));
			}
			else if(this.$store.state.respId) window.sessionStorage.setItem('respId', this.$store.state.respId);

			if (window.sessionStorage.getItem('respToken') && !this.$store.state.respToken) {
				this.$store.commit('setRespToken', window.sessionStorage.getItem('respToken'));
			}
			else if(this.$store.state.respToken) window.sessionStorage.setItem('respToken', this.$store.state.respToken);

			let formdata = {
				getparams: this.$store.state.getparams,
				survid: this.survid,
				respid: this.$store.state.respId,
				resptoken: this.$store.state.respToken,
				sesstoken: this.$store.state.wlsesstoken,
			};

			axios.post(
				this.urlroot + "surveys/post-collect/get-survey.php", formdata
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.respStatus) {
					self.respStatus = ret.respStatus;
					if(self.respStatus === 'cati_user_login_fail') { //this must be handled here because no ret.survey is returned
						self.errorType = 'respondent-catiloginfail';
						return false;
					}
				}
				if(ret.survnotfound && parseInt(ret.survnotfound) === 1) {
					window.location = "https://yourwavelength.com";
					return false;
				}
				if (ret.survey) {
					document.title = ret.survey.survname_public;
					self.$store.commit('setSurveyObject', ret.survey);

					//OMT, Callout, RegRatings, Perceptual. 'working', 'publish', 'closed'

					if(ret.exiturls) {
						self.exiturl_quotafull = ret.exiturls.quotafull;
						self.exiturl_screenout = ret.exiturls.screenout;
						self.exiturl_complete = ret.exiturls.complete;
					}

					if(ret.respid) {
						self.$store.commit('setRespId', ret.respid);
						sessionStorage.setItem('respId', ret.respid);
					}
					if(ret.respToken) {
						self.$store.commit('setRespToken', ret.respToken);
						sessionStorage.setItem('respToken', ret.respToken);
					}
					if(ret.hookids) {
						self.$store.commit('setHookIds', ret.hookids);
					}

					self.handleSurveyStatus(); //before anything else handle closed/working surveys - may need to break if triggered
					self.prepareAnswerStore();
					self.handleRespStatus();

					//panelqignore - populate relevant questions to panel respondents (hiding of appropriate qs is done in Question)
					if (self.$store.state.getparams.source && self.$store.state.getparams.source.length > 0 && self.$store.state.getparams.source !== 'CATI') {
						self.$store.commit('updateAnswer', {qid: self.survey.fnameq, selections: 'panel'});
						self.$store.commit('updateAnswer', {qid: self.survey.lnameq, selections: 'panel'});
						self.$store.commit('updateAnswer', {qid: self.survey.emailq, selections: 'research@arn.com.au'});
					}

					//handle any EDM contact details
					if(ret.contactDetails) {
						self.$store.commit('updateAnswer', {qid: self.survey.fnameq, selections: ret.contactDetails.fname});
						self.$store.commit('updateAnswer', {qid: self.survey.lnameq, selections: ret.contactDetails.lname});
						self.$store.commit('updateAnswer', {qid: self.survey.emailq, selections: ret.contactDetails.email});
						self.$store.commit('updateAnswer', {qid: self.survey.genderq, selections: ret.contactDetails.gender});
						self.hasLoadedFromContactList = true;
					}

					self.retrieveAnswersFromLocal(); //must run after answer store is created
					if (ret.answers) { //populate any returned answers from the server for CATI resume into answer array
						for (let i = 0; i < ret.answers.length; i++) {
							self.$store.commit('updateAnswerFromServerRetrieve', ret.answers[i]);
						}
					}
					if (ret.otextanswers) {
						for (let i = 0; i < ret.otextanswers.length; i++) {
							self.$store.commit('setOAns', ret.otextanswers[i]);
						}
					}

					if(sessionStorage.getItem('activePageNo') && parseInt(sessionStorage.getItem('activePageNo')) > 0) {
						self.activePage = parseInt(sessionStorage.getItem('activePageNo'));
					}

					if(self.respStatus === 'hooks' || self.respStatus === 'introfin') { //handle F5 for Callout or F5/Resume for OMT
						if(self.$store.state.hookIdsRemaining.length === 0) { //check if survey is now completed (no more hooks left)
							self.finaliseSurvey();
							return false;
						}
						if(self.survey.type === 'Callout') {
							self.inHooksSection = true;
						}
						else if(self.survey.type === 'OMT') { //instructions etc
							if(self.$store.state.sessStorageExists === true) { //F5 refresh - straight back to hooks
								self.inHooksSection = true;
							}
							else { //DMT resume - show instructions/welcome screen
								self.showingInfoView = 'omtinstructions';
							}
						}
					}
				}
			}).catch(function (error) {
				console.log(error);
			});

		},
		retrieveAnswersFromLocal() {
			this.$store.commit('fetchFromLocalStorage');
		},
		prepareAnswerStore() {
			if (this.$store.state.surveyOb.questions) {
				let ans = [];
				let oans = []; //for otext responses
				for (let q = 0; q < this.$store.state.surveyOb.questions.length; q++) {
					let myq = this.$store.state.surveyOb.questions[q];
					if (this.$store.state.qTypeUsesArray.indexOf(myq.type) > -1) { //create an empty array
						ans.push({qid: this.$store.state.surveyOb.questions[q].qid, selections: []});
					}
					else { //just a single empty entry
						ans.push({qid: this.$store.state.surveyOb.questions[q].qid, selections: ''});
					}
					if (this.$store.state.surveyOb.questions[q].oTextOpts !== '' && this.$store.state.surveyOb.questions[q].oTextOpts > 0) {
						oans.push({qid: this.$store.state.surveyOb.questions[q].qid, response: ''})
					}
				}
				this.$store.commit('initAnswerArray', ans);
				this.$store.commit('initOAnswerArray', oans);

				//now prepare the matrix questions
				let mats = [];
				let mymatrix = []; //open a new matrix array
				let headqid = 0;
				let mattype = '';
				for (let q = 0; q < this.$store.state.surveyOb.questions.length; q++) {
					let myq = this.$store.state.surveyOb.questions[q];
					let qt = myq.type;
					if(this.$store.state.matrixTypes.includes(qt)) {
						if (qt === 'MatrixHeadRadio' || qt === 'MatrixHeadCheck') {
							headqid = myq.qid;
							mattype = myq.type;
							mymatrix = []; //open a new matrix array
						}
						mymatrix.push(myq);
						if (qt === 'MatrixEndRadio' || qt === 'MatrixEndCheck') {
							if(headqid > 0) {
								mats.push({headqid: headqid, mattype: mattype, questions: mymatrix});
							}
							mymatrix = []; //open a new matrix array
							headqid = 0;
							mattype = '';
						}
					}
				}
				this.$store.commit('setMatrices', mats);
			}
		},
		goToNextPage() {
			let qsOnCurrentPage = this.survey.questions.filter(item => item.pageNo === this.activePage);
			//mandatories for all questions on current page (if not CATI)
			if (this.$store.state.isCATI === false) {
				if(qsOnCurrentPage.length > 0) { //eg if we just have a header text 'question' on the page, don't validate it
					for (let i = 0; i < qsOnCurrentPage.length; i++) {
						let myq = qsOnCurrentPage[i];
						let ignoringForPanelQIgnore = false;
						if(this.$store.state.getparams.source && this.$store.state.getparams.source.length > 0 && this.$store.state.getparams.source !== 'CATI') {
							let mypos = this.$store.state.surveyOb.questions.findIndex(item => item.qid === myq.qid);
							if(mypos < this.$store.state.surveyOb.panelqignore) {
								ignoringForPanelQIgnore = true;
							}
						}
						if(myq.mandatory === 'Yes' && ignoringForPanelQIgnore === false) {
							if (this.validateAnswer(myq) === false) {
								console.log(myq)
								window.alert("Please answer required questions");
								this.saveAnswers(true);
								return false;
							}
						}
					}
				}
			}

			//work out the next page to show before saving so refresh lands on correct page
			if (this.activePage < this.survey.lastPageNo) {
				this.isSavingLastQPage = false;
				let nextpageid = this.activePage + 1; //default - can be overridden by skips etc
				//handle skip logic if it exists
				for (let i = 0; i < qsOnCurrentPage.length; i++) {
					let myqu = qsOnCurrentPage[i];
					let myans = this.getAnswersAsArray(myqu);
					if(myqu.skips.length > 0) { //normal skip type logic
						for(let j=0; j<myans.length; j++) {
							let thisans = parseInt(myans[j]);
							let skipmatch = myqu.skips.find(item => item.optid === thisans);
							if(skipmatch) {
								let targqid = skipmatch.skipToQidTarget;
								let targq = this.survey.questions.find(item => item.qid === targqid);
								if(targq) {
									nextpageid = targq.pageNo;
									break;
								}
							}
						}
					}
					else if(myqu.type === 'Textarea' || myqu.type === 'Text' || myqu.type === 'Number') { //force skip on text question type input
						if(parseInt(myqu.textForceSkipQid) > 0) {
							let targqid = parseInt(myqu.textForceSkipQid);
							let targq = this.survey.questions.find(item => item.qid === targqid);
							if(targq) {
								nextpageid = targq.pageNo;
								break;
							}
						}
					}
				}
				//now that we have a new target page based on skips, check for any branching requirements that might override that
				if(this.survey.branches && this.survey.branches.length > 0) {
					window.AnswersByQID = [];
					for(let z=0; z<this.survey.questions.length; z++) {
						let mq = this.survey.questions[z];
						window.AnswersByQID[mq.qid] = this.getAnswersAsArray(mq);
					}
					nextpageid = this.checkBranchingNextPage(nextpageid);
				}
				this.nextPageId = nextpageid; //saveAnswers() uses this once callback is complete to nav
				this.saveAnswers(); //save to sessionStorage and server
			}
			else { //this is the last page
				this.isSavingLastQPage = true;
				this.saveAnswers();
			}
		},
		checkBranchingNextPage(nextpageid) {
			for(let a=0; a<this.survey.branches.length; a++) {
				let mybranch = this.survey.branches[a];
				if(mybranch.attachToPageID === nextpageid) { //this branch affects the page we're looking to show next
					let branchlogictest = new window.OperatTest(mybranch.showIfRaw);
					if(branchlogictest.out != "1") { //branching logic fails, do not show this page, nsec++ and repeat
						nextpageid++;
						this.checkBranchingNextPage(nextpageid);
					}
				}
			}
			return nextpageid;
		},
		goToPage(pageno) {
			this.isPageChanging = true; //to reset the position of page
			this.validateOverlayShown = false;
			setTimeout(function() {
				this.activePage = pageno;
				sessionStorage.setItem('activePageNo', this.activePage);
				this.nextPageId = null; //reset this
				this.isPageChanging = false;
			}.bind(this), 50);
		},
		goToHooks() { //called from OMT Instructions page
			this.showingInfoView = '';
			this.inHooksSection = true;
		},
		handleRespStatus() {
			//cati_user_login_fail is handled at top of getInitialLoad return as there is no survey object returned with that error (this function runs after survey checks)
			if(this.respStatus === 'disqualified') {
				if(this.isCATI) this.disqualifyCall();
				else {
					if(this.exiturl_screenout) {
						this.isSaving = true; //sometimes takes a while for the new page to load if external
						window.location = this.exiturl_screenout;
					}
					else { //old fallback = show quotafull exiturl
						this.errorType = 'respondent-quotafull';
					}
					return false;
				}
			}
			else if(this.respStatus === 'quotafull') {
				if(this.exiturl_quotafull) {
					this.isSaving = true; //sometimes takes a while for the new page to load if external
					window.location = this.exiturl_quotafull;
				}
				else { //old fallback = engine/error.php?errid=quotafull
					this.errorType = 'respondent-quotafull';
				}
				return false;
			}
			else if(this.respStatus === 'complete') {
				this.errorType = 'respondent-alreadycompleted'; //old = engine/error.php?errid=completed"
			}
			else if(this.respStatus === 'cati_user_login_fail') {
				this.errorType = 'respondent-catiloginfail';
				return false;
			}
			else if((this.respStatus !== 'introfin' && this.respStatus !== 'hooks') && this.survey.type === 'OMT' && this.survey.status === 'publish' && this.survey.status.returnUseOnly === 1) {
				this.errorType = 'survey-closed';
			}
			return true;
		},
		handleSurveyStatus() {
			if(this.survey.status === 'closed') { //'working', 'publish', 'closed'
				this.errorType = 'survey-closed';
			}
			else if(this.survey.status === 'working') {
				this.errorType = 'survey-notready';
			}
			return true;
		},
		saveAnswers(doNotRunDQScreen = false) { //this is for normal questions - hooks manage their own save in their SFC
			if(!this.isSaving) {
				this.isSaving = true;
				//also save to localstorage to handle any page refresh
				sessionStorage.setItem('answers', JSON.stringify(this.$store.state.answers));
				sessionStorage.setItem('oanswers', JSON.stringify(this.$store.state.oanswers));

				let self = this
				let doNotRunDQScreenInt = 0
				if(doNotRunDQScreen === true) doNotRunDQScreenInt = 1
				let formdata = {
					getparams: this.$store.state.getparams,
					survid: this.survid,
					respid: this.$store.state.respId,
					resptoken: this.$store.state.respToken,
					sesstoken: this.$store.state.wlsesstoken,
					answers: this.$store.state.answers,
					oanswers: this.$store.state.oanswers,
					currentpageno: this.activePage,
					lastpageno: this.survey.lastPageNo,
					doNotRunDQScreen: doNotRunDQScreenInt, //if this is not validated page, save but don't DQ respondents
				};

				//cati go to next page immediately (everyone else goes after the save has returned - check screener)
				if(this.isCATI && !this.isSavingLastQPage && this.nextPageId) {
					this.goToPage(this.nextPageId);
				}

				axios.post(
					this.urlroot + "surveys/post-collect/save-answers.php", formdata
				).then(function (response) {
					self.isSaving = false;
					var ret = response.data; //response.data is returned info
					if (ret.respStatus) {
						self.respStatus = ret.respStatus;
					}
					if (ret.survey) { //handle survey status (eg check for closed/working)
						self.$store.commit('setSurveyObject', ret.survey);
						self.handleSurveyStatus();
					}
					self.handleRespStatus();
					if (ret.respid) {
						self.$store.commit('setRespId', ret.respid);
						sessionStorage.setItem('respId', ret.respid);
					}
					if (ret.respToken) {
						self.$store.commit('setRespToken', ret.respToken);
						sessionStorage.setItem('respToken', ret.respToken);
					}
					if (self.isSavingLastQPage) {
						if(self.survey.hookcount > 0 && self.$store.state.hookIdsRemaining.length > 0) {
							if(self.survey.type === 'Callout') {
								self.inHooksSection = true; //show hooks section
							}
							else if(self.survey.type === 'OMT') {
								self.showingInfoView = 'omtinstructions';
							}
						}
						else { //respondent has finished and there are no hooks - close the survey
							if(self.isCATI) {
								self.isSavingLastQPage = false;
								self.validateOverlayShown = true; //show the validation screen first
							}
							else {
								self.finaliseSurvey();
							}
						}
						return false;
					}
					else if(self.nextPageId) {
						self.goToPage(self.nextPageId);
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		prepareNextHook(completedHookId) {
			this.$store.commit('removeHookFromRemainingList', completedHookId) //remove completed hook id from still to do array
			if(this.$store.state.hookIdsRemaining.length === 0) { //check if survey is now completed (no more hooks left)
				this.inHooksSection = false;
				this.showingInfoView = 'wait';
				this.finaliseSurvey();
			}
		},
		finaliseSurvey() {
			this.isSaving = true;
			let self = this;
			let formdata = {
				survid: this.survid,
				respid: this.$store.state.respId,
				finalise: true,
			};
			axios.post(
				this.urlroot + "surveys/post-collect/finalise-respondent.php", formdata
			).then(function (response) {
				self.isSaving = false;
				var ret = response.data; //response.data is returned info
				if(self.isCATI) {
					self.returnToCatiHome();
				}
				else {
					if (ret.respStatus) {
						self.respStatus = ret.respStatus;
						self.handleRespStatus();
					}
					if(ret.exiturl) {
						self.exiturl_complete = ret.exiturl;
					}
					if(ret.exitImmediately && ret.exitImmediately === true) {
						self.isSaving = true; //sometimes takes a while for the new page to load if external
						window.location = self.exiturl_complete; //exit to the panel complete/exiturl immediately
					}
					else { //show the finished screen
						self.fnameForFinish = ret.fname;
						self.showingInfoView = ret.showPageName;
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		abortOrDisqualifyCall(type) {
			let self = this
			let formdata = {
				survid: this.$store.state.survid,
				respid: this.$store.state.respId,
				fn: type,
			};
			axios.post(
				this.urlroot + "surveys/post-collect/abort-or-dq-caticall.php", formdata
			).then(function () { //response
				self.isSaving = false;
				//let ret = response.data //response.data is returned info
				self.clearLocalStorage()
				self.returnToCatiHome()
			}).catch(function (error) {
				console.log(error)
			});
		},
		abortCall() {
			let c = confirm("Are you sure you want to ABORT this caller?");
			if(c == true) {
				this.abortOrDisqualifyCall('abortCall')
			}
		},
		disqualifyCall() {
			var c = confirm("Are you sure you want to DISQUALIFY this caller?");
			if(c == true) {
				this.abortOrDisqualifyCall('disqualifyCall');
				// this.clearLocalStorage();
			}
		},
		showCATIValidationOverlay() {
			this.saveAnswers();
			this.validateOverlayShown = true;
		},
		returnToCatiHome() {
			this.clearLocalStorage();
			window.location = "https://app.yourwavelength.com/cati-launch/" + this.$store.state.survid;
		},
	},
	watch: {},
	mounted() {
		if (this.survid > 0) {
			if(this.$store.state.getparams.source === 'CATI') {
				this.$store.commit('setIsCati', true);
				if(this.$store.state.getparams.stream && parseInt(this.$store.state.getparams.stream) > 0) {
					this.$store.commit('setWLUserId', parseInt(this.$store.state.getparams.stream));
				}
			}
			//wlsesstoken is handled in mounted() in App.vue - it needs to be read and cleaned prior to this
			if(this.$store.state.getparams.id) {
				this.$store.commit('setRespId', this.$store.state.getparams.id);
			}
			if(this.$store.state.getparams.resptoken) { //this is not used yet - but for potential future resumes
				this.$store.commit('setRespToken', this.$store.state.getparams.resptoken);
			}
			this.getInitialLoad();
		}
	}

}
</script>

<style scoped>
.top-section {
	background-color: #888;
	position: relative;
}
.top-section-inner {
	max-width: 1600px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	height: 60px;
	position: relative;
}
.percentage-section {
	height: 100%;
	flex-grow: 1;
	min-width: 500px;
}
.cati-buttons {
	min-width: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}
.structure {
	display: flex;
}
.leftnav {
	width: 300px;
	min-width: 300px;
	max-width: 300px;
	height: calc(100vh - 60px);
	overflow-y: auto;
	background: #F1F1F1;
}
.survey-view {
	background: #FFF;
	flex-grow: 1;
	min-height: calc(100vh - 94px);
	padding: 30px;
}
.notcatiview .top-section-inner {
	max-width: 1000px;
}
.notcatiview .survey-view {
	max-width: 900px;
	width: 90%;
	margin: 0 auto;
}
.btn {
	color: #FFF;
	font-weight: bold;
	cursor: pointer;
	background-color: #23A6EE;
	width: 140px;
	display: block;
	padding: 12px 10px 10px;
	margin: 30px 30px 30px 0;
	float: left;
	text-align: center;
	transition: color 0.5s, transform 0.2s, background-color 0.2s;
	outline: none;
	overflow: hidden;
	box-shadow: none;
	position: relative;
}
.btn::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	transition: opacity 0.3s, border 0.3s;
}
.btn::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 200px;
	height: 200px;
	background-color: #43C6FE;
	border-color: transparent;
	border-radius: 50%;
	transform: translate(-10px, -70px) scale(0.1);
	opacity: 0;
	z-index: -1;
	transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}
.btn:hover {
	transform: translateY(0px);
}
.btn:hover::before {
	opacity: 0;
}
.btn:hover::after {
	opacity: 1;
	transform-origin: 100px 100px;
	transform: scale(1) translate(10px, -70px);
}
.btn:active {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	transform: translateY(-1px);
}
.catifuncs-btn {
	padding: 5px 20px;
	width: 120px;
	margin-left: 10px;
	display: block;
	float: right;
	font-size: 13px;
	font-weight: normal;
	background-color: #EFEFEF;
	color: #000;
}

@media screen and (max-width: 800px) {
	.percentage-section {
		min-width: unset;
	}
}
@media screen and (max-width: 660px) {
	.btn.btn-animated {
		width: 100%;
	}
}
</style>