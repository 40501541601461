<template>
	<div class="survey-view">
		<div class="">
			<div id="">
				<div class="introText">
					<p v-if="$store.state.surveyOb.finishprizetag.length > 0">{{$store.state.surveyOb.finishprizetag}}<br/><br/></p>
					<p class="bottomIntro">Would you like to tell some friends about our music surveys?  Just enter their email addresses below and we'll send them an invitation!</p>
					<p class="bottomIntro">If you don't wish to invite anyone else just click the Exit button below.</p>
				</div>

				<div id="formFields">
					<p class="namer"><span class="boldme">Person 1</span> Email Address</p><input type="text" v-model="email1" class="referrerIn" /><br/><br/>
					<p class="namer"><span class="boldme">Person 2</span> Email Address</p><input type="text" v-model="email2" class="referrerIn" /><br/><br/>
					<p class="namer"><span class="boldme">Person 3</span> Email Address</p><input type="text" v-model="email3" class="referrerIn" /><br/><br/>
					<p class="astext">*Note - to avoid any chance of spamming people, we will only email anyone listed above if they have not received a previous invitation, and if they are not already signed up as a YouChoose member.</p>
				</div>

				<div id="bholder">
					<div v-if="isReferring === false">
						<span @click="submitRefer" class="ycbtn">Submit</span> or <a class="ycbtn exitBtn" :href="fin_exiturl">Exit</a>
					</div>
					<div v-else>
						<p style="color: #FFF;">Thanks...redirecting in a moment</p>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "FinishBauerRefer",
	components: {},
	props: {
		fname: String,
		exiturl: String,
	},
	data: function () {
		return {
			isReferring: false,
			email1: '',
			email2: '',
			email3: '',
		}
	},
	computed: {
		fin_exiturl() {
			if(!this.exiturl || this.exiturl.length === 0) {
				return "https://yourwavelength.com";
			}
			return this.exiturl;
		}
	},
	methods: {
		submitRefer() {
			let self = this;
			let formdata = {
				referSurvid: this.$store.state.survid,
				referrer: this.$store.state.respId,
				refer1: this.email1,
				refer2: this.email2,
				refer3: this.email3,
			};
			if(this.email1.length > 0 || this.email2.length > 0 || this.email3.length > 0) {
				self.isReferring = true;
				axios.post(
					this.$store.state.urlroot + "surveys/post-collect/do-referral-invites.php", formdata
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.respStatus) {
						self.respStatus = ret.respStatus;
						self.handleRespStatus();
					}
					window.location = self.fin_exiturl;
				}).catch(function (error) {
					console.log(error);
				});
			}
			else { //if no people are invited just push them to the exit url instead
				window.location = this.exiturl;
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.survey-view p {
	font-size: 16px;
	font-weight: bold;
	line-height: 1.4;
}
#bholder {
	background: #272d31;
	border-radius: 0 0 30px 30px;
	text-align: center;
	padding: 50px;
	color: #999;
}
.introText {
	padding: 50px 50px 10px 50px;
	background: #f2f2f2;
	border-radius: 30px 30px 0 0;
}
#formFields {
	background: #f2f2f2;
	padding: 50px;
}
.referrerIn {
	font-size: 14px;
	padding: 0 10px;
	height: 40px;
	margin-top: 10px;
	border-radius: 6px;
	border: 1px solid #ccc;
	width: calc(100% - 22px);
}
.astext {
	color: #999;
}
.ycbtn, .ycbtn:hover, .ycbtn:active, .ycbtn:visited {
	background-color: #23A6EE ;
	padding: 15px 30px;
	border-radius: 6px;
	display: inline;
	cursor: pointer;
	color: #FFF;
	font-weight: bold;
	text-decoration: none;
	transition: background 0.2s linear;
	font-size: 18px;
	margin: 10px 50px 100px 50px;
}
.ycbtn:hover {
	background-color: #43C6FE;
}
.ycbtn.exitBtn {
	background: #596268;
}
.ycbtn.exitBtn:hover {
	background: #6f787e;
}

@media only screen and (max-width: 1000px) {
	.survey-view p {
		font-size: 16px;
	}
}
@media only screen and (max-width: 700px) {
	#bholder a {
		padding: 13px 10px;
		width: calc(100% - 20px);
		display: block;
		margin: 10px 0;
		font-size: 14px;
	}
	.introText, #formFields {
		padding: 40px;
	}
}
@media only screen and (max-width: 450px) {
	.introText, #formFields {
		padding: 30px;
	}
}
</style>