<template>
	<div class="header">
		<div class="inside">
			<div class="image-container">
				<img src="~@/assets/backstage-header.png" alt="Backstage logo" height="60px" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BackstageHeader",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.header {
	background-color: #FFF;
	height: 100px;
}
.inside {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.image-container {
	padding: unset;
	height: auto;
}
</style>